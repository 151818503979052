header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 80%);
  transition: background .25s ease-out;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include break(small-screen) {
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .wrapper-nav {
      display: flex;
      align-items: center;
      gap: 35px;
      width: 100%;
      padding: 30px 0;

      @include break(small-screen) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0;
      }

      @include break(tablet) {
        padding: 0;
      }

      .logo {
        width: 195px;
        height: auto;
      }

      .nav {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 32px;

        @include break(small-desktop-only) {
          display: flex !important;
          opacity: 1 !important;
          transform: translateX(0) !important;
        }

        @include break(small-screen) {
          display: none;
        }

        ul.primary-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          gap: 20px;
          transition: gap .25s ease-out;

          @media only screen and (max-width: 1410px) {
            gap: 0;
          }

          &:hover {

            a,
            .btn-dropdown {
              color: $input-border;
            }
          }

          .btn-dropdown {
            border: 0;
            background: transparent;
            padding: 0;
          }

          a,
          .btn-dropdown {
            text-decoration: none;
            color: $white;
            padding: 10px 15px;
            transition: color 0.3s;
          }

          li.menu-item {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 17px;
            line-height: 120%;
            text-wrap: nowrap;

            transition: opacity 0.3s ease, color 0.3s ease;

            @include break(small-screen) {
              font-size: 28px;
            }

            &:hover {

              a,
              .btn-dropdown {
                color: $dark;
              }
            }

            &-has-children {
              position: relative;

              .btn-dropdown {
                position: relative;
                cursor: pointer;

                .dropdown {
                  position: absolute;
                  top: 50%;
                  right: -3px;
                  width: 10px;
                  height: 10px;
                  transform: translateY(-50%);

                  span {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: $white;
                    -webkit-transform: rotate(0);
                    transform: rotate(0);
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

                    &:first-of-type {
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                    }
                  }
                }
              }

              @include break(small-screen) {
                flex-wrap: wrap;

                .btn-dropdown {
                  display: block;
                  padding: 0;

                  .dropdown {
                    top: calc(50% - -2px);
                    right: -20px;
                    width: 12px;
                    height: 12px;
                  }

                  &.active {
                    span {
                      background-color: $dark;

                      &:first-of-type {
                        transform: rotate(45deg);
                        top: 5px;
                      }

                      &:last-of-type {
                        transform: rotate(-45deg);
                        bottom: 5px;
                      }
                    }
                  }
                }
              }
            }

            &.open.menu-item-has-children {

              ul.sub-menu {
                z-index: 99;
                opacity: 1;
                transform: translateY(0);
                visibility: visible;

                &::before {
                  display: block;
                  height: 100%;
                }

                @include break(small-screen) {
                  display: block;
                }
              }

              .btn-dropdown {
                .dropdown {
                  span {
                    &:first-of-type {
                      opacity: 0;
                    }
                  }
                }
              }
            }

            ul.sub-menu {
              position: absolute;
              top: calc(100% + 36px);
              left: 0;
              right: 0;
              width: 100vw;
              padding-bottom: $pad-title;
              opacity: 0;
              visibility: hidden;
              z-index: -999;
              transition: visibility 0s linear, opacity 0.25s ease-out;

              @include break(small-screen) {
                position: relative;
                display: none;
                padding-top: $pad-med_mobile;
                padding-bottom: 10px;
              }

              &::before {
                content: "";
                display: none;
                position: absolute;
                top: 0;
                left: -100vw;
                height: 0%;
                width: 200vw;
                background-color: $white;
              }

              li.menu-item {
                display: block;
                margin: 0;
                width: 100%;
                line-height: 120%;

                @include break(small-screen) {
                  font-size: 18px;
                }

                &:not(:last-child) {
                  a {
                    padding-bottom: 13px;

                    @include break(small-screen) {
                      padding-bottom: 16px;
                    }
                  }
                }

                a {
                  display: inline-block;
                  width: 100%;
                }
              }
            }
          }
        }

        &.active {
          padding: 0;
          flex: 1;
          align-items: flex-start;
          width: 100%;

          ul.primary-menu {
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            width: 100%;

            li.menu-item {
              a {
                padding: 0;
                padding-right: 20px;
              }
            }
          }

          ul.sub-menu {
            li>.btn-dropdown {
              display: none;
            }
          }
        }
      }

      .wrapper-btn {
        display: flex;
        height: 100%;
        gap: 8px;

        @include break(small-screen) {
          display: none;
        }

        .btn-menu-cta,
        .btn-language {
          background: rgba(255, 255, 255, 0.10);
          backdrop-filter: blur(2px);
        }

        .btn-menu-cta {
          @include font-regular;
          align-items: center;
          font-size: 14px;
          text-wrap: nowrap;

          .arrow-wrapper {
            width: 14px;
            height: 14px;
          }
        }

        .btn-language {
          border-radius: 6px;

          .wpml-ls-legacy-list-horizontal {
            padding: 0;
            border: none;

            a {
              color: $white;
              padding: 16px 22px;
              font-size: 12px;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .nav_mobile {
    display: none;
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;

    @include break(small-screen) {
      display: block;
      position: absolute;
      top: 22px;
      right: 30px;
    }

    .burger {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 35px;
      height: 12px;

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
          top: 0;
        }

        &:last-of-type {
          bottom: 0;
        }
      }
    }

    &.active {
      span {
        background-color: $dark;

        &:first-of-type {
          transform: rotate(45deg);
          top: 5px;
        }

        &:last-of-type {
          transform: rotate(-45deg);
          bottom: 5px;
        }
      }
    }
  }

  &.active {
    background: $white;

    @include break(small-screen) {
      height: 100vh;
    }

    .site-logo {
      svg path {
        transition: fill .25s ease-out;
        fill: $dark;
      }
    }

    .container {
      @include break(small-screen) {
        height: 100%;
      }

      .wrapper-nav {
        @include break(small-screen) {
          height: 100%;
        }

        .nav {
          ul.primary-menu {
            li.menu-item {

              a,
              a::after,
              button.btn-dropdown,
              button.btn-dropdown::after {
                color: $dark;
              }

              button.btn-dropdown {
                .dropdown span {
                  background-color: $dark;
                }
              }
            }

            &:hover {
              &>li.menu-item {

                &>a,
                a::after,
                &>button.btn-dropdown,
                button.btn-dropdown::after {
                  color: $input-border;
                }

                &>button.btn-dropdown {
                  .dropdown span {
                    background-color: $input-border;
                  }
                }

                &:hover {

                  &>a,
                  a::after,
                  &>button.btn-dropdown,
                  button.btn-dropdown::after {
                    color: $dark;
                  }

                  &>button.btn-dropdown {
                    .dropdown span {
                      background-color: $dark;
                    }
                  }
                }
              }
            }
          }

          ul.sub-menu {
            li.menu-item {

              &>a {
                color: $dark;
              }
            }

            &:hover {
              li.menu-item {

                &>a {
                  color: $input-border;
                }

                &.active {

                  &>a {
                    color: $dark;
                  }
                }
              }
            }
          }
        }

        .wrapper-btn {
          @include break(small-screen) {
            display: flex;
            height: fit-content;
            position: absolute;
            bottom: 20vh;
          }

          .btn-menu-cta,
          .btn-language {
            background: $dark;
            backdrop-filter: initial;
          }
        }
      }
    }
  }

  &.dark {
    background: none;

    &.active {
      background: $white;

      ul.primary-menu {
        &:hover {
          li.menu-item {

            &>a,
            &>.btn-dropdown {
              color: $input-border;
            }

            &>button.btn-dropdown {
              .dropdown span {
                background-color: $input-border;
              }
            }
          }
        }
      }
    }

    .container {
      .site-logo {
        svg {
          path {
            fill: $dark;
          }
        }
      }

      .wrapper-nav {
        .nav {
          ul.primary-menu {

            li.menu-item {

              a,
              .btn-dropdown {
                color: $dark;
              }

              .dropdown span {
                background-color: $dark;
              }
            }
          }
        }
      }

      .wrapper-btn {

        .btn-menu-cta,
        .btn-language {
          background-color: $dark;
        }
      }
    }
  }
}
