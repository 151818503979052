.pagination {
    .nav-links {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        gap: 10px;
        position: relative;
        
        @include break(tablet) {
            gap: 20px;
        }

        .page-numbers {
            font-size: 20px;
            text-align: center;
            line-height: 150%;

            @include break(tablet) {
                min-width: fit-content;
                margin-bottom: 0;
            }

            &.current {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                background-color: $white;
                fill: var(--Soleil-Clair, #FFE);
                backdrop-filter: blur(6.40000057220459px);
            }

            &.prev,
            &.next {
                position: absolute;
                font-size: 15px;

                @include break(tablet) {
                    display: none;
                }
            }

            &.prev {
                left: -130px;
            }

            &.next {
                right: -110px;
            }
        }
    }
}