.module-services-02-subventions {
    padding: $pad-large 0;

    @include break(tablet) {
        padding: $pad-large_mobile 0;
        overflow-x: hidden;
    }

    .container {
        @include break(tablet) {
            position: relative;
            padding-bottom: 80px;
        }

        &>.row>.col {
            width: 100%;
        }
    }

    .wrapper-title {
        max-width: 60%;

        @include break(tablet) {
            max-width: 100%;
        }

        .title-h2 {
            margin-bottom: $pad-small;

            @include break(tablet) {
                margin-bottom: 24px;
            }
        }
    }

    .wrapper-description {
        display: flex;
        justify-content: space-between;

        @include break(tablet) {
            flex-direction: column;
        }

        .description {
            font-size: 20px;
            max-width: 60%;
            padding-bottom: $pad-large;

            @include break(tablet) {
                max-width: 100%;
                font-size: 1rem;
                padding-bottom: $pad-med_mobile;
            }
        }

        .btn {
            width: fit-content;
            height: fit-content;

            @include break(tablet) {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                text-wrap: nowrap;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1425px;
        
        @include break(tablet) {
            width: 100%;
            margin-left: 5%;
            padding-top: 50px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .card {
            padding: 50px 35px;
            text-align: center;
            border-radius: $rounded-lg;
            background-color: $white;

            @include break(tablet) {
                width: 272px;
                display: inline-block;
            }

            .title-h4 {
                @include font-medium;
                font-size: 20px;
                padding-bottom: $pad-small;
                height: 110px;
                position: relative;
                z-index: 1;
                
                @include break(tablet) {
                    font-size: 18px;
                }
            }
            
            picture {
                height: 110px;
                display: flex;
                justify-content: center;

                .img {
                    max-width: 140px;
                    width: 100%;
                    object-fit: cover;
                    padding-bottom: $pad-small;
                }
            }

            .card-body {
                .wrap-item {
                    padding-bottom: $pad-xsmall;

                    .item-title {
                        font-size: 14px;
                        line-height: 120%;
                        padding-bottom: 8px;
                    }

                    .item-value {
                        @include font-medium;
                        font-size: 15px;
                        line-height: 150%;
                    }

                    &.service-title {
                        .item-value {
                            border-radius: 6px;
                            font-size: 12px;
                            width: fit-content;
                            margin: 0 auto;
                            padding: 6px 7px;
                            background-color: $primary-yellow;

                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }

        }
    }
}