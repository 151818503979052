/*  --- Variable ---  */
$font-size: 18px;
$container: 1425px;

/*  --- Padding ---  */
$pad-xxsmall: 8px;
$pad-xsmall: 16px;
$pad-content: 20px;
$pad-small: 32px;
$pad-med: 48px;
$pad-title: 60px;
$pad-large: 80px;
$pad-xlarge: 120px;

$pad-med_mobile: 32px;
$pad-large_mobile: 40px;
$pad-xlarge_mobile: 60px;

/* --- Border --- */
$rounded-lg: 12px;


//COLOR
$dark: #000000;
$dark-light: #2F2E2E;
$white: #FFFFFF;

$primary-grey: #EDE6E4;
$primary-blue: #88B1DB;
$primary-blue-light: #F0F1FF;
$primary-yellow: #FFFDA9;
$primary-yellow-light: #FFFFEE;
$primary-brown: #9F8B84;

$secondary-blue: #202096;
$secondary-orange: #FF855D;
$secondary-grey: #777372;

$input-border: #CCC1BD;
$input-bg: #DFD8D6;