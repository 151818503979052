/*  --- MEDIA QUERIES ---  */

$break-mobile: 768px;
$break-small-screen: 1280px;
$break-tablet: 991px;
$break-container: 1460px;


@mixin break($media) {

/* -- @include break(mobile)-- */
  @if $media == mobile {
    @media only screen and (max-width: $break-mobile) { @content; }
  }

/* -- @include break(v-mobile)-- */
  @else if $media == v-mobile{
    @media only screen and (max-width: $break-mobile) and (orientation: portrait){ @content; }
  }

/* -- @include break(tablet)-- */
  @else if $media == tablet{
    @media only screen and (max-width: $break-tablet) , screen and (max-width: 1024px) and (orientation: portrait){ @content; }
  }

/* -- @include break(tablet-only) -- */
  @else if $media == tablet-only{
    @media only screen and (max-width: $break-tablet) and (min-width: $break-mobile){ @content; }
  }

/* -- @include break(desktop-and-tablet)-- */
   @else if $media == desktop-and-tablet{
    @media only screen and (min-width: $break-mobile) { @content; }
  }

  /* -- @include break(small-screen)-- */
    @else if $media == small-screen{
      @media only screen and (max-width: $break-small-screen) { @content; }
    }

  /* -- @include break(container)-- */
    @else if $media == container{
      @media only screen and (max-width: $break-container) { @content; }
    }

  /* -- @include break(desktop-only)-- */
    @else if $media == desktop-only{
      @media only screen and (min-width: $break-tablet) { @content; }
    }

    /* -- @include break(small-desktop-only)-- */
    @else if $media == small-desktop-only{
      @media only screen and (min-width: $break-small-screen) { @content; }
    }

  /* -- @include break(ie-only)-- */
    @else if $media == ie-only{
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @content; }
    }
}
