.template-news-archive {
    padding-top: 230px;

    @include break(tablet) {
        padding-top: calc(60px + 97px);
    }

    .title-h:not(.words-wrapper) {
        font-size: 60px;
        margin-top: 0;
        margin-bottom: $pad-title;
        line-height: 106%;

        @include break(tablet) {
            font-size: 40px;
            margin-bottom: $pad-med;
        }
    }

    .last_post {
        padding-bottom: 140px;

        @include break(tablet) {
            padding-bottom: $pad-xlarge_mobile;
        }

        .card-post_highlight {
            .categories {
                top: 30px;
                left: 25px;
            }

            .img-container {
                height: 487px;
                margin-bottom: 24px;

                @include break(mobile) {
                    height: 225px;
                    margin-bottom: 12px;
                }

                .link {
                    right: 0;
                    align-items: center;
                    font-size: 16px;
                    @include font-medium;
                    line-height: 120%;

                    @include break(tablet) {
                        right: 1rem;
                    }

                    span {
                        @include break(small-screen) {
                            display: none;
                        }
                    }
                }
            }

            .image {
                max-height: 487px;
                clip-path: url(#clip-path-img-highlight);

                @include break(mobile) {
                    clip-path: url(#clip-path-img-mobile);
                    height: 225px;
                    max-height: 225px;
                    margin-bottom: 12px;
                }
            }

            .title-h4 {
                font-size: 22px;
                line-height: 120%;

                @include break(tablet) {
                    font-size: 18px;
                }
            }
        }
    }

    .archive {
        .filter_container {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;

            @include break(mobile) {
                margin-bottom: $pad-med_mobile;
            }

            .title-h3 {
                margin-bottom: 50px;

                @include break(mobile) {
                    margin-bottom: $pad-large_mobile;
                }
            }

            .filter-block {
                display: flex;
                gap: 12px;

                .drop_filter {
                    position: relative;
                    width: fit-content;

                    @include break(mobile) {
                        width: 100%;
                    }

                    select {
                        min-width: 295px;
                        padding: 16px 18px;
                        border: 1px solid $input-border;
                        color: $secondary-grey;
                        border-radius: 8px;
                        font-size: 15px;
                        line-height: 150%;
                        appearance: none;
                        background-color: $input-bg;

                        @include break(mobile) {
                            min-width: fit-content;
                            margin-left: 0px;
                            width: 100%;
                        }
                    }

                    svg {
                        position: absolute;
                        top: 50%;
                        right: 14px;
                        transform: translateY(-50%);
                        pointer-events: none
                    }
                }
            }
        }

    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 60px 40px;
        grid-auto-flow: row;
        grid-template-areas:
            ". .";
        margin-left: auto;
        margin-right: 0;
        padding-bottom: 130px;

        @include break(small-screen) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        @include break(mobile) {
            grid-template-columns: 1fr;
            grid-template-areas:
                ".";
            gap: 0 16px;
            padding-bottom: 60px;
        }
    }

    a {
        position: relative;
        height: fit-content;

        @include break(tablet) {
            height: fit-content;
            min-width: 100%;
            margin-bottom: $pad-med_mobile;
        }

        &:hover {
            &:not(a.link) {
                .arrow-wrapper {
                    .arrow-btn {
                        &.first {
                            opacity: 0;
                            transform: translateX(100%);
                        }

                        &.last {
                            opacity: 1;
                            transform: translateX(0) rotate(-45deg);
                        }
                    }
                }
            }
        }

        .img-container {
            position: relative;
            height: 294px;
            margin-bottom: 1rem;

            @include break(mobile) {
                height: 225px;
                margin-bottom: 12px;
            }

            .link {
                position: absolute;
                bottom: 0.5rem;
                right: 1rem;
                padding: 0;

                @include break(tablet) {
                    bottom: 0.5rem;
                }
            }
        }

        .image {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 294px;
            object-fit: cover;
            clip-path: url(#clip-path-img-mobile);

            @include break(mobile) {
                height: 225px;
                margin-bottom: 12px;
            }
        }

        .content {
            .title-h3 {
                font-size: 22px;
                line-height: 120%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @include break(tablet) {
                    font-size: 18px;
                }
            }

            .title-h4 {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        .categories {
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }


    .link:not(a.link) .arrow-wrapper {
        .arrow-btn {
            &.first {
                transform: translateX(0);
            }

            &.last {
                transform: translateX(-100%);
            }
        }
    }
}