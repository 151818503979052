// FORM

form {
    position: relative;

    label {
        @include font-medium;
        font-size: 15px;
        line-height: 148%;

        @include break(tablet) {
            font-size: 14px;
        }
    }

    input,
    textarea,
    select {
        @include font-regular;
        margin-top: 14px;
        appearance: none;
        border: none;
        border-radius: 8px;
        background: $input-bg;
        color: $secondary-grey;
        width: 100%;
        padding: 15px 18px;
        margin-bottom: 5px;
        transition: all 0.4s;
        border: 1px solid $input-border;

        @include break(mobile) {
            margin-top: 12px;
            font-size: 14px;
        }

        &:focus {
            outline: none;
            border: 2px solid $input-border;
        }

        &[type='submit'] {
            position: relative;
            display: inline-block;
            width: auto;
            margin-top: 40px;
            padding: 16px 50px 16px 22px;
            color: $white;
            font-size: 16px;
            @include font-medium;
            text-decoration: none;
            text-align: center;
            line-height: 120%;
            border: none;
            border-radius: 6px;
            background-color: $dark-light;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $dark;
            }

            @include break(tablet) {
                margin-top: 12px;
            }
        }

        &[type='checkbox'] {
            width: 20px;
            height: 20px;
            border-radius: 2px;
            margin-top: 0px;
            margin-right: 10px;
            margin-bottom: 0;
            border: 1px solid $input-border;

            &:focus,
            &:checked {
                border: 3px solid $white;
                background-color: $input-bg;
            }
        }

        &[type='radio'] {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-top: 0px;
            margin-right: 10px;
            margin-bottom: 0;
            border: 3px solid $white;
            border: 1px solid $input-border;

            &:focus,
            &:checked {
                border: 3px solid $white;
                background-color: $input-bg;
            }
        }
    }

    .submit-wrapper {
        position: relative;
        width: fit-content;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.8048 14.3653C25.0235 14.1466 25.0235 13.792 24.8048 13.5734L17.8672 7.47572C17.4263 7.03488 16.7034 7.53587 16.9614 8.10343L20.359 12.8799H3.36078C3.0515 12.8799 2.80078 13.0805 2.80078 13.3279V14.6719C2.80078 14.9193 3.0515 15.1199 3.36078 15.1199H20.4955L16.9614 19.8352C16.7034 20.4028 17.4263 20.9038 17.8672 20.4629L24.8048 14.3653Z' fill='white'/%3E%3C/svg%3E");
            display: block;
            width: 28px;
            height: 28px;
            position: absolute;
            top: calc(50% + 18px);
            right: 10px;
            z-index: 1;
            opacity: 1;
            transition: all .25s ease-out;
            transform: translate(0, -50%);

            @include break(tablet) {
                top: calc(50% + 4px);
            }
        }

        &::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.8048 14.3653C25.0235 14.1466 25.0235 13.792 24.8048 13.5734L17.8672 7.47572C17.4263 7.03488 16.7034 7.53587 16.9614 8.10343L20.359 12.8799H3.36078C3.0515 12.8799 2.80078 13.0805 2.80078 13.3279V14.6719C2.80078 14.9193 3.0515 15.1199 3.36078 15.1199H20.4955L16.9614 19.8352C16.7034 20.4028 17.4263 20.9038 17.8672 20.4629L24.8048 14.3653Z' fill='white'/%3E%3C/svg%3E");
            display: block;
            width: 28px;
            height: 28px;
            position: absolute;
            top: calc(50% + 18px);
            right: 10px;
            z-index: 1;
            opacity: 0;
            transition: all .25s ease-out;
            transform: translate(-100%, -50%);

            @include break(tablet) {
                top: calc(50% + 4px);
            }
        }

        &:hover {
            &::before {
                opacity: 0;
                transform: translate(100%, -50%);
            }

            &::after {
                opacity: 1;
                transform: translate(0, -50%);
            }
        }
    }

    .wpcf7-form-control-wrap[data-name='contact-select'] {
        position: relative;

        &::before {
            content: " ";
            background-color: #343436;
            height: 2px;
            width: 9px;
            border-radius: 0.5px;
            right: 23px;
            position: absolute;
            top: 52%;
            transform: translate(-50%, -50%) rotate(-50deg);
            pointer-events: none;
        }

        &::after {
            content: " ";
            background-color: #343436;
            height: 2px;
            width: 9px;
            border-radius: 0.5px;
            right: 28px;
            position: absolute;
            top: 52%;
            transform: translate(-50%, -50%) rotate(50deg);
            transition: all 0.4s;
            pointer-events: none;
        }
    }

    .wpcf7-checkbox,
    .wpcf7-radio {
        margin-top: 11px;
        display: block;

        @include break(mobile) {
            margin-top: 5px;
        }

        label {
            display: flex;
            align-items: center;
            margin-right: 35px;
        }

        .wpcf7-list-item {
            margin: 0;
        }
    }

    .wpcf7-response-output {
        border: none !important;
        padding: 0 !important;
        z-index: 10;
    }


    p {
        margin-bottom: 20px;
    }

    &.sent .wpcf7-response-output {
        border: none;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        padding: 20px 40px;
        position: absolute;
        bottom: -35px;
        left: -35px;
        right: -35px;
        bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px !important;
        font-size: 20px;
        line-height: 150%;
    }
}

/* Subscribe newsletter form */
#mc_embed_signup {
    // false;
    clear: left;
    // width: 600px;
}

#mc_embed_signup_scroll {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    border-radius: 0.5rem;

    &:focus-within {
        outline: none;
    }

    .email {
        flex: 1;
        width: calc(100% - 44px);
        grid-area: 1 / 2 / 2 / 4;
        margin-top: 0;
        padding: 13px 55px 13px 18px;
        font-size: 1rem;
        line-height: 160%;
        color: $dark;
        background-color: $white;
        border: 2px solid $white;
        border-radius: 6px;
        outline: none;

        @include break(tablet) {
            width: 100%;
        }

        &::placeholder {
            color: $dark;
            opacity: 0.5;
        }
    }

    .btn-submit {
        position: absolute;
        top: 0;
        right: calc(54px - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        padding: 0;
        color: $white;
        background-color: $dark;
        border: 1px solid $white;
        border-radius: 6px;
        overflow: hidden;
        transition: background-color 0.3s, color 0.3s;

        @include break(tablet) {
            right: 0;
        }

        &:hover {
            color: white;
            background-color: $dark-light;
            cursor: pointer;
        }
    }

    div.mce_inline_error {
        width: calc(100% - 44px);
        border-radius: 6px;
        background-color: $secondary-orange !important;
    }

    input.mce_inline_error {
        border-color: $secondary-orange !important;
    }
}