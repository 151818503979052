.module-03-panneau-cartes {
  background-color: $white;
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .row {
    &.mobile {
      display: flex;

      @include break(tablet) {
        display: none;
      }

      &.mobile-display {
        display: none;

        @include break(tablet) {
          display: block;

          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .wrapper-elements {
    position: relative;
    height: 100%;
  }

  .wrapper-title {
    .title-h2 {
      margin-bottom: $pad-title;

      @include break(tablet) {
        margin-bottom: $pad-large_mobile;
      }
    }
  }

  .wrapper-link {
    position: absolute;
    bottom: 0;
    left: 0;

    @include break(tablet) {
      display: none;
    }
  }

  .card-titles {
    display: flex;
    flex-direction: column;

    @include break(tablet) {
      flex-direction: row;
      justify-content: space-around;
      padding-bottom: $pad-med_mobile - 10px;
    }

    .card-title {
      position: relative;
      cursor: pointer;
      font-size: 22px;
      line-height: 120%;
      padding: 10px 0;
      opacity: 0.4;
      transition: all 0.25s ease-out;

      @include break(tablet) {
        opacity: 1;

        p {
          display: none;
          transition: all 0.25s ease-out;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 11px;
        height: 11px;
        background-color: $secondary-grey;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        opacity: 0;
        transform: translateY(-50%);
        transition: opacity 0.25s ease-out;

        @include break(tablet) {
          opacity: 1;
        }
      }

      &.active {
        position: relative;
        padding-left: 24px;
        opacity: 1;

        &::before {
          opacity: 1;
          background-color: $primary-blue;
        }

        @include break(tablet) {
          padding-left: 0;
        }
      }
    }
  }

  .card-contents,
  .swiper-panel-cards {
    position: relative;
    overflow: hidden;
    min-height: 569px;

    @include break(tablet) {
      min-height: 934px;
    }

    .card-content {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-blue-light;
      border-radius: $rounded-lg;
      padding: $pad-content;

      @include break(tablet) {
        padding: $rounded-lg;
        height: fit-content;
      }

      &.active {
        display: flex;
        transition: transform 0.3s ease-in-out;
      }

      .row {
        @include break(tablet) {
          margin-left: -$rounded-lg;
          margin-right: -$rounded-lg;
        }
      }

      .left {
        padding: $pad-med - $pad-content $pad-med / 2 $pad-med $pad-med + 18px;
        display: flex;
        flex-direction: column;

        @include break(tablet) {
          padding: 32px 24px;
        }

        .index {
          flex: 1;

          @include break(tablet) {
            padding-bottom: $pad-med_mobile;
            font-size: 18px;
          }
        }

        .title-h3 {
          padding-bottom: $pad-content;

          @include break(tablet) {
            padding-bottom: 18px;
          }
        }

        .description {
          font-size: 1rem;
        }
      }

      .right {
        height: 100%;
        padding-left: $pad-med / 2;

        width: 100%;
        position: relative;

        @include break(tablet) {
          padding-right: $pad-med / 2;
          height: fit-content;
        }

        .image {
          display: block;
          width: 100%;
          height: 100%;
          max-width: 100%;
          box-sizing: border-box;
          background-clip: border-box;
          object-fit: cover;

          &.mobile {
            display: none;
          }

          @include break(tablet) {
            max-height: 394px;

            &:not(.mobile) {
              display: none;
            }

            &.mobile {
              display: block;
              height: 394px;
            }
          }
        }

        .tooltip {
          bottom: calc(100% - 60px);
          right: 48px;
        }
      }
    }
  }

  .swiper-panel-cards {
    .swiper-wrapper {
      padding-top: 40px;
    }

    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      top: 0px !important;
      height: fit-content;
    }

    .card-content {
      display: flex;
      position: relative;
    }

    .swiper-pagination-bullet {
      background: $secondary-grey;
      opacity: 1;
      
      &-active {
        background: $primary-blue;
      }
    }
  }
}