/*
Theme Name: Theme Name
*/




#adminmenu li.wp-menu-separator {
    background-color: currentColor;
}

// .logged-in.admin-bar {
//     .site-header {
//         top: 32px;
//     }
// }