.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$pad-med;
  margin-right: -$pad-med;

  @include break(tablet) {

    .col-70,
    .col-65,
    .col-60,
    .col-50,
    .col-40,
    .col-35,
    .col-30 {
      flex: 0 0 100%;
    }
  }
}

.col {
  padding-left: $pad-med;
  padding-right: $pad-med;
}

.col-70 {
  flex: 0 0 70%;
}

.col-65 {
  flex: 0 0 65%;
}

.col-60 {
  flex: 0 0 60%;
}

.col-50 {
  flex: 0 0 50%;
}

.col-40 {
  flex: 0 0 40%;
}

.col-35 {
  flex: 0 0 35%;
}

.col-30 {
  flex: 0 0 30%;
}

.col-reverse {
  flex-direction: row-reverse;
}

.divider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 50px 0;
  background-color: $dark;
  opacity: 0.2;
}