.module-11-industries {
  position: relative;
  min-height: 830px;
  padding: $pad-large 0;
  color: $white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @include break(tablet) {
    padding: $pad-large_mobile 0 300px 0;
  }

  .filter {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
  }

  .contenu {
    position: relative;
    z-index: 1;

    &>.row:first-of-type {
      max-width: 70%;
      @include break(tablet) {
        max-width: 100%;
      }
    }
  }

  .wrapper-title {
    padding-bottom: $pad-small;
    
    @include break(tablet) {
      padding-bottom: 24px;
    }
  }

  .col-50:first-child {
    padding-right: 0;
    @include break(tablet) {
      padding-right: 48px;
    }

    .description {
      padding-bottom: $pad-med;

      @include break(tablet) {
        padding-bottom: $pad-med_mobile;
        font-size: 18px;
      }
    }

    .btn {
      background-color: #E6E6E6;
      color: $dark;
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
    max-width: 80%;
    padding-top: 170px;
    margin-right: 0;
    margin-left: auto;

    @include break(tablet) {
      padding-top: $pad-med_mobile;
      max-width: 100%;
    }

    .card {
      flex: 0 1 calc(50% - 10px);
      width: 100%;
      padding: $pad-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: transparent;
      border-radius: 16px;
      border: 1px solid $white;
      transition: all .25s ease;

      @include break(tablet) {
        flex: 0 1 100%;
        padding: 18px 22px;
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(22px);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(22px);
      }

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        
        .arrow-wrapper {
          overflow: hidden;
        }

        &:hover {
          .arrow-wrapper {
            .arrow-btn {
              &.first {
                opacity: 0;
                transform: translateX(100%);
              }

              &.last {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }

        @include break(tablet) {
          flex-direction: row;
        }

        .title-h3 {
          font-size: 22px;
          line-height: 120%;
          padding-bottom: $pad-large_mobile;
          
          @include break(tablet) {
            font-size: 18px;
            padding-bottom: 0;
            max-width: 90%;
          }
        }

        .arrow-wrapper {
          position: relative;
          width: 25px;
          height: 25px;
          margin-left: 10px;

          .arrow-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity 0.3s, transform 0.3s ease-out;

            &.first {
              opacity: 1;
              transform: translateX(0);
              z-index: 2;
            }

            &.last {
              opacity: 0;
              transform: translateX(-100%);
              z-index: 1;
            }
          }
        }
      }
    }
  }
}



