.template-news {
	padding-top: 113px;

	.header_article {
		height: 610px;
		position: relative;
		margin-bottom: 100px;

		@include break(tablet) {
			height: 345px;
			margin-bottom: $pad-large_mobile;
		}

		.img {
			height: 100%;
			width: 100%;
			background-image: url(../../dist/img/placeholder.jpg);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		> .container {
			position: relative;

			.categorie {
				position: absolute;
				bottom: calc(40px - 6px);
				left: 0;
				padding: 6px 13px;
				border-radius: 40px;
				background-color: $primary-yellow;
				@include font-medium;
				font-size: 14px;
				line-height: 120%;
			}

			.tooltip {
				right: 0;
			}
		}
	}

	.page-container {
		.title-h1 {
			font-size: 48px;
			line-height: 110%;
			margin-top: 0;

			@include break(tablet) {
				font-size: 32px;
				margin-bottom: 50px;
			}
		}
	}

	.content {
		padding-bottom: 200px;

		@include break(tablet) {
			padding-bottom: $pad-large_mobile;
		}

		.grid-infos {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 40px;

			@include break(tablet) {
				grid-template-columns: 1fr;
				gap: 20px;
			}

			.grid-infos-titre {
				margin-bottom: 24px;
				font-size: 14px;

				@include break(tablet) {
					margin-bottom: 10px;
				}
			}

			.redaction-authors {
				display: inline-flex;
				gap: 0 3px;
				flex-wrap: wrap;
				font-size: 15px;
			}

			.partenaires-logos-grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 6px;

				@include break(tablet) {
					grid-template-columns: repeat(3, 1fr);
				}

				.logo-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: $rounded-lg;
					background-color: $white;
					padding: 10px 0;
					max-height: 53px;

					picture {
						height: 53px;
						width: auto;
						max-width: 70%;

						img {
							height: 100%;
						}
					}
				}
			}
		}

		.lieu {
			.lieu-titre {
				padding-bottom: 10px;
			}

			.divider {
				margin-top: 80px;

				@include break(tablet) {
					margin-top: 50px;
				}
			}
		}

		.description {
			.preambule {
				font-size: 26px;
				line-height: 125%;
				margin-bottom: 80px;

				@include break(tablet) {
					font-size: 22px;
					margin-bottom: 24px;
				}
			}
		}

		.content-author {
			margin-bottom: 40px;

			.author-title {
				@include font-medium;
				margin-bottom: 10px;
			}

			.author-description {
				font-size: 15px;
			}
		}

		.content-footer {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@include break(tablet) {
				flex-direction: column;
			}

			.content-footer-wrap {
				display: flex;
                align-items: flex-start;
				gap: 40px;
				font-size: 15px;
				line-height: 150%;

				@include break(tablet) {
					margin-bottom: 30px;
				}

                .content-footer-title {
                    text-wrap: nowrap;
                }

				p {
					margin-bottom: 0;

					&:first-child {
						margin-bottom: 12px;
					}
				}
			}

			.categorie-wrapper {
				display: flex;
				width: 100%;
				gap: 10px;
				justify-content: flex-end;
                flex-wrap: wrap;

				.categorie {
					text-wrap: nowrap;
				}
			}

			.categorie {
				padding: 6px 13px;
				@include font-medium;
				font-size: 14px;
				line-height: 120%;
				height: fit-content;
				border-radius: 40px;
				background-color: $primary-yellow;
			}
		}
	}

	.related-news {
		padding-top: 145px;
		padding-bottom: 120px;
		background-color: $white;

		@include break(tablet) {
			padding: 60px 0;
		}

		.title-h2 {
			@include break(tablet) {
				padding-bottom: 40px;
			}
		}

		.grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 40px;
			margin-bottom: 100px;

			@include break(tablet) {
				grid-template-columns: 1fr;
				margin-bottom: $pad-xlarge_mobile;
			}

			.card-post {
				&:last-of-type {
					margin-bottom: 0;
				}

				.content {
					padding-bottom: 0;
				}

				.img-container {
					height: 290px;

					@include break(tablet) {
						height: 225px;
					}
				}

				.image {
					max-height: 290px;

					@include break(tablet) {
						max-height: 225px;
					}
				}
			}
		}
	}

	.back-to {
		.arrow-wrapper {
			margin-left: 0;
			margin-right: 20px;

			@include break(tablet) {
				margin-top: -5px;
			}

			.arrow-btn {
				&.first {
					opacity: 1;
					transform: translateX(0);
					z-index: 2;
				}

				&.last {
					opacity: 0;
					transform: translateX(100%);
					z-index: 1;
				}
			}
		}

		&:hover {
			.arrow-wrapper {
				.arrow-btn {
					&.first {
						opacity: 0;
						transform: translateX(-100%);
					}

					&.last {
						opacity: 1;
						transform: translateX(0);
					}
				}
			}
		}
	}
}
