.module-services-01-graphique {
    padding: $pad-large 0;
    background-color: $white;

    @include break(tablet) {
        padding: $pad-large_mobile 0;
    }

    .wrapper-title {
        .title-h2 {
            margin-bottom: $pad-small;

            @include break(tablet) {
                margin-bottom: 24px;
            }
        }
    }

    .description {
        font-size: 20px;
        max-width: 60%;
        padding-bottom: $pad-large;

        @include break(tablet) {
            max-width: 100%;
            font-size: 1rem;
            padding-bottom: $pad-med_mobile;
        }
    }


    .schema {
        @include break(mobile) {
            width: 100%;
            margin: 0 auto;
            position: relative;
        }

        .schema-desktop {
            display: block;
            padding: 0 $pad-med $pad-large $pad-med;

            @include break(mobile) {
                display: none;
            }
        }

        .schema-mobile {
            display: none;

            @include break(mobile) {
                display: block;
                position: relative;
                width: 100%;
                max-width: 100vw;
                margin-bottom: 60px;
            }

            .swiper-cards {
                width: 100%;
                max-width: 100vw;
                margin-top: calc(62px + 22px);

                .card {
                    text-align: center;
                    padding: 36px 50px;
                    min-height: 500px;
                    width: 100%;
                    max-width: 100vw;
                    border-radius: 8px;
                    background: linear-gradient(180deg, #F0F1FF 36%, rgba(240, 241, 255, 0.00) 100%);

                    &:last-child {
                        background: linear-gradient(180deg, #FFE 36%, rgba(255, 255, 238, 0.00) 100%);
                    }

                    .title-h4 {
                        padding-bottom: 25px;
                    }

                    .card-text {
                        padding-bottom: $pad-content;
                    }

                    .title {
                        font-size: 14px;
                        line-height: 120%;
                        padding-bottom: 8px
                    }

                    .value {
                        @include font-medium;
                        font-size: 15px;
                        line-height: 120%;
                    }
                }
            }

            .swiper-pagination {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                top: 0;
                height: fit-content;
                
                @include break(mobile) {
                    height: 50px;
                }

                .swiper-pagination-bullet {
                    width: auto;
                    height: auto;
                    padding: 10px 0;
                    @include font-medium;
                    line-height: 120%;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                    background: none;
                    border-radius: 0;
                    opacity: 0.4;
                    transition: all .25s ease-out;
                }

                .swiper-pagination-bullet-active {
                    opacity: 1;
                    background: none;
                    border-bottom: 2px solid $primary-blue;
                }
            }

        }

        .courbe {
            display: none;
            position: absolute;
            bottom: 60px;
            left: 48px;
            z-index: 99;
            transition: transform 0.25s ease-out;

            @include break(mobile) {
                display: block;
            }
        }
    }

    .col-card-show {
        padding: 0 $pad-large;

        @include break(mobile) {
            padding-left: 48px;
            padding-right: 48px;
        }

        .card-show {
            padding: 60px;
            background-color: $primary-blue-light;
            border-radius: $rounded-lg;

            @include break(mobile) {
                padding: 36px;
            }

            .card-text {
                .title-h3 {
                    font-size: 28px;
                    padding-bottom: $pad-content;

                    @include break(mobile) {
                        padding-bottom: 1rem;
                        font-size: 20px;
                    }
                }

                .description {
                    max-width: 80%;
                    padding-bottom: 40px;

                    @include break(tablet) {
                        max-width: 100%;
                        padding-bottom: 25px;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}