.card-post {
    position: relative;
    height: fit-content;

    @include break(tablet) {
        height: fit-content;
        min-width: 100%;
        margin-bottom: $pad-med_mobile;
    }

    &:hover {
        &:not(a.link) {
            .arrow-wrapper {
                .arrow-btn {
                    &.first {
                        opacity: 0;
                        transform: translateY(-100%);
                    }

                    &.last {
                        opacity: 1;
                        transform: translateY(0) rotate(-45deg);
                    }
                }
            }
        }
    }

    .img-container {
        position: relative;
        height: 294px;
        margin-bottom: 1rem;

        @include break(tablet) {
            margin-bottom: 12px;
        }

        .link {
            position: absolute;
            bottom: 0.5rem;
            right: 2rem;
            padding: 0;

            @include break(tablet) {
                bottom: 0.5rem;
            }

        }

    }

    .image {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 294px;
        object-fit: cover;
        clip-path: url(#clip-path-img-mobile);

        @include break(tablet) {
            height: 225px;
            margin-bottom: 12px;
        }
    }

    .content {
        .title-h3 {
            font-size: 22px;
            line-height: 120%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @include break(tablet) {
                font-size: 18px;
            }
        }
    }

    .categories {
        position: absolute;
        top: 12px;
        left: 12px;
        text-wrap: nowrap;
    }

    .link:not(a.link) .arrow-wrapper {
        .arrow-btn {
            &.first {
                transform: translateY(0);
            }

            &.last {
                transform: translateY(100%);
            }
        }
    }
}