// Popup
.popup {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9998;
    right: 0;

    .inner {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        @include break(tablet) {
            width: 100%;
            padding-right: 5%;
            padding-left: 5%;
        }

        .bg {
            position: absolute;
            top: 0;
            left: -28%;
            right: 0;
            bottom: 0;
            height: 100%;
            width: auto;
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            max-height: 80vh;
            overflow-y: scroll;

            .btn {
                margin-top: 50px;

                @include break(tablet) {
                    margin-top: 30px;
                }
            }
        }
    }
}