.module-news-03-media {
    margin-bottom: 50px;

    @include break(tablet) {
        margin-bottom: 40px;
    }

    .cover {
        width: 100%;
        height: 400px;
        padding: 230px 170px;
        border-radius: $rounded-lg;
        position: relative;

        @include break(tablet) {
            height: 200px;
            display: flex;
            justify-content: center;
            min-width: -webkit-fill-available;
        }

        .play-btn {
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            position: absolute;
            left: 0;
            top: 0;
            padding: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                .play-btn-object {
                    transform: scale(1.1);
                }
            }
        }

        .play-btn-object {
            width: 110px;
            height: 110px;
            display: grid;
            place-items: center;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: $primary-yellow;
            transition: all 0.4s;
            cursor: pointer;

            @include break(tablet) {
                width: 83px;
                height: 83px;
            }
        }

        svg {
            position: absolute;
            width: 38px;
        }
    }

    .content-video {
        padding: 15px 20px;
        border-radius: $rounded-lg;
    }

    .wp-caption {
        margin-bottom: 0;
    }
}