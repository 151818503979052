.module-02-contenu {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  &.layout-img-txt {
    .wrapper-media {
      order: 1;
    }

    .wrapper-texte {
      order: 2;
    }
  }

  &.layout-txt-img {
    .wrapper-media {
      @include break(desktop-only) {
        order: 2;
      }
    }

    .wrapper-texte {
      @include break(desktop-only) {
        order: 1;
      }
    }
  }

  &.small,
  &.medium,
  &.large {
    .row {
      .wrapper-texte {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }

  .row {
    .title-h2 {
      margin-bottom: $pad-small;
    }

    .description {
      font-size: 1.25rem;
      line-height: 150%;

      @include break(tablet) {
        font-size: 18px;
      }
    }

    .wrapper-title {
      .title-h2 {
        margin-bottom: 100px;

        @include break(tablet) {
          margin-bottom: $pad-small;
        }
      }
    }

    .wrapper-media {
      position: relative;

      @include break(tablet) {
        margin-bottom: $pad-med_mobile;
      }

      .tooltip {
        right: 48px;
      }
    }

    a.btn {
      margin-top: $pad-med;

      @include break(tablet) {
        margin-top: $pad-med_mobile;
      }
    }
  }

  &.space {
    @include break(tablet) {
      .wrapper-title {
        order: 2;
      }

      .wrapper-media {
        order: 1;
      }

      .wrapper-texte {
        order: 3;
      }
    }

    .divider {
      display: block;
      height: 1px;
      width: 100%;
      margin-bottom: 60px;
      background-color: $dark;
      opacity: 0.2;

      @include break(tablet) {
        margin-top: 20px;
        margin-bottom: 30px;
      }

      &.last {
        margin-top: 60px;
        margin-bottom: 50px;

        @include break(tablet) {
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }

    &.description {
      padding: 0;

      .wrapper-texte {
        @include break(desktop-only) {
          padding-left: 0;
        }
      }
    }

    &.section {
      .wrapper-title {
        .title-h2 {
          margin-bottom: 75px;

          @include break(tablet) {
            margin-bottom: $pad-title;
          }
        }
      }
    }

    .wrapper-title {
      width: 100%;
    }

    .wrapper-media {
      display: flex;
      align-items: flex-end;

      @include break(tablet) {
        margin-bottom: 0;

        img:not(.mobile) {
          display: block;
        }
      }
    }

    .wrapper-icon-animated {
      position: relative;
      width: 204px;
      height: 204px;
      margin-bottom: -30px;
      overflow: hidden;

      @include break(tablet) {
        width: 113px;
        height: 113px;
        margin-bottom: $pad-med_mobile;
      }

      .icon-animated {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:nth-child(1) {
          animation: fadeInOut 5s infinite;
        }

        &:nth-child(2) {
          animation: fadeInOut 5s infinite;
          animation-delay: 1.66s;
        }

        &:nth-child(3) {
          animation: fadeInOut 5s infinite;
          animation-delay: 3.3s;
        }
      }
    }


    .wrapper-texte {
      .section {
        border-bottom: 1px solid $dark;
        margin-bottom: 35px;
        padding-bottom: 35px;

        &:last-child {
          margin-bottom: 0;
        }

        .title-h3 {
          font-size: 28px;
          padding-bottom: 16px;

          @include break(tablet) {
            font-size: 20px;
          }
        }

        .description {
          margin-bottom: 0;

          @include break(tablet) {
            font-size: 1rem;
          }
        }
      }
    }

    &.layout-2cols {
      padding: $pad-large 0;

      @include break(tablet) {
        padding: $pad-large_mobile 0;
      }

      .divider {
        display: none;
      }

      .wrapper-title {
        .title-h2 {
          margin-bottom: $pad-small;
        }
      }

      .wrapper-media {
        justify-content: center;
        align-items: center;

        .wrapper-icon-animated {
          width: 313px;
          height: 311px;
        }
      }
    }
  }

}