.module-08-actualites_realisations {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0 calc(40px + 50px) 0;
  }

  .container {
    @include break(tablet) {
      position: relative;
    }
  }

  .wrapper-title {
    padding-bottom: $pad-small;

    @include break(tablet) {
      padding-bottom: 24px;
      max-width: 60%;
    }
  }

  .wrapper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $pad-large;

    @include break(tablet) {
      padding-bottom: $pad-large_mobile;
    }

    .description {
      font-size: 1.25rem;
      line-height: 150%;

      @include break(tablet) {
        font-size: 1.125rem;
      }
    }
  }

  // Slider //
  &.slider {
    .description {
      max-width: 50%;

      @include break(tablet) {
        max-width: 100%;
      }
    }

    .wrapper-content {
      .wrapper-link {
        @include break(tablet) {
          width: 100%;
          text-align: left;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .swiper {
      width: calc(100% - 48px * 2);
      height: auto;

      .swiper-slide {
        position: relative;
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        a {
          width: 100%;
          height: auto;

          @include break(tablet) {
            padding-bottom: 150px;
          }

          &:hover {
            .arrow-wrapper {
              position: relative;
              width: 25px;
              height: 25px;
              margin-left: 10px;

              .arrow-btn {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: opacity 0.3s, transform 0.3s ease-out;

                &.first {
                  opacity: 1;
                  transform: translateX(0);
                  z-index: 2;
                }

                &.last {
                  opacity: 0;
                  transform: translateX(-100%);
                  z-index: 1;
                }
              }
            }

            &:hover {
              .arrow-wrapper {
                .arrow-btn {
                  &.first {
                    opacity: 0;
                    transform: translateX(100%);
                  }

                  &.last {
                    opacity: 1;
                    transform: translateX(0);
                  }
                }
              }
            }
          }
        }

        img {
          display: block;
          width: 100%;
          height: 682px;
          max-height: 682px;
          object-fit: cover;
          margin-bottom: 55px;
          clip-path: url(#clip-path-img-slider);

          @include break(small-screen) {
            height: 500px;
          }

          @include break(tablet) {
            height: 225px;
            margin-bottom: 12px;
            clip-path: url(#clip-path-img-mobile);
          }
        }

        .slide-content {
          position: absolute;
          bottom: 32px;
          right: 30px;
          text-align: left;
          max-width: 35%;

          @include break(tablet) {
            max-width: 100%;
            bottom: initial;
            top: 240px;
            right: 0;
            height: 60px;
          }

          .title-h3 {
            font-size: 1.7rem;
            line-height: 120%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            @include break(small-screen) {
              font-size: 20px;
            }
          }

          .link {
            font-size: 16px;
            padding-left: 0;
            display: flex;
            align-items: center;

            @include break(tablet) {
              position: absolute;
              bottom: 65px;
              right: 0;
            }

            .text {
              @include break(tablet) {
                display: none;
              }
            }

            .arrow-wrapper {
              width: 28px;
              height: 28px;

              .arrow-btn {
                @include break(tablet) {
                  top: 0;
                }
              }
            }
          }
        }

        .categories {
          position: absolute;
          top: 35px;
          left: 40px;

          @include break(tablet) {
            top: 12px;
            left: 12px;
          }
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        background-position: center;
        background-size: 45px;
        background-repeat: no-repeat;
        top: calc(100% - 22px);

        @include break(tablet) {
          display: none;
        }

        &::after {
          content: "";
        }
      }

      .swiper-button-prev {
        background-image: url("data:image/svg+xml,%3Csvg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon / slide-left'%3E%3Cpath id='Rectangle 449' d='M16.1747 22.2992C15.8076 22.6585 15.8076 23.241 16.1747 23.6002L27.82 33.6178C28.56 34.342 29.7735 33.519 29.3404 32.5865L22.4374 23.5723C22.325 23.3304 22.325 23.0528 22.4374 22.8109L29.3404 13.3129C29.7735 12.3804 28.56 11.5574 27.82 12.2816L16.1747 22.2992Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      .swiper-button-next {
        background-image: url("data:image/svg+xml,%3Csvg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon / slide-right'%3E%3Cpath id='Rectangle 449' d='M30.9261 22.2992C31.2932 22.6585 31.2932 23.241 30.9261 23.6002L19.2808 33.6178C18.5408 34.342 17.3274 33.519 17.7604 32.5865L24.6635 23.5723C24.7758 23.3304 24.7758 23.0528 24.6635 22.8109L17.7604 13.3129C17.3274 12.3804 18.5408 11.5574 19.2808 12.2816L30.9261 22.2992Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
        left: 63px;
        right: auto;
      }

      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        @include break(desktop-only) {
          width: fit-content;
          margin-left: calc(100% - 50% - 220px);
          z-index: 999999;
        }

        @include break(tablet) {
          bottom: 50px;
        }
      }

      .swiper-pagination-bullet {
        width: 65px;
        height: 3px;
        flex-shrink: 0;
        border-radius: 50px;
        background-color: $dark;

        @include break(tablet) {
          width: 29px;
        }
      }
    }
  }

  // Liste //
  &.liste {
    .wrapper-content {
      .wrapper-link {
        @include break(tablet) {
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
        }
      }
    }

    .description {
      max-width: 60%;

      @include break(tablet) {
        max-width: 100%;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(450px, 1fr));
      grid-template-rows: 1fr;
      gap: 0px 40px;
      grid-auto-flow: row;
      grid-template-areas:
        ". .";
      margin-left: auto;
      margin-right: 0;
      padding-left: 0;

      @include break(small-screen) {
        grid-template-columns: repeat(2, minmax(420px, 1fr));
      }

      @include break(tablet) {
        grid-template-columns: 1fr;
        grid-template-areas:
          ".";
        padding-left: 48px;
        gap: 32px 0;
        padding-bottom: $pad-title;
      }

      a {
        position: relative;

        @include break(tablet) {
          height: fit-content;
        }

        &:hover {
          .link {
            .arrow-wrapper {
              .arrow-btn {
                &.first {
                  opacity: 0;
                  transform: translateY(-100%);
                }

                &.last {
                  opacity: 1;
                  transform: translateY(0) rotate(-45deg);
                }
              }
            }
          }
        }

        .img-container {
          position: relative;
          margin-bottom: 1rem;

          @include break(tablet) {
            margin-bottom: 12px;
          }

          .link {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            padding: 0;

            @include break(small-screen) {
              bottom: 1.5rem;
            }

            @include break(tablet) {
              bottom: 0.5rem;
            }
          }
        }

        .image {
          display: block;
          width: 100%;
          height: 297px;
          object-fit: cover;
          margin-bottom: 1rem;
          clip-path: url(#clip-path-img-desktop);

          @include break(tablet) {
            height: 225px;
            margin-bottom: 12px;
            clip-path: url(#clip-path-img-mobile);
          }
        }

        .slide-content {
          .title-h3 {
            font-size: 22px;
            line-height: 120%;

            @include break(tablet) {
              font-size: 18px;
            }
          }
        }

        .categories {
          position: absolute;
          top: 12px;
          left: 12px;
        }
      }
    }

    .link:not(a.btn) .arrow-wrapper {
      .arrow-btn {
        &.first {
          transform: translateY(0);
        }

        &.last {
          transform: translateY(100%);
        }
      }
    }
  }

  // Grid (4 actus) //
  &.grid {
    .container .row>.col:first-child {
      width: 100%;
    }

    .wrapper-title {
      @include break(tablet) {
        max-width: 100%;
      }
    }

    .wrapper-content {
      padding-bottom: $pad-title;

      @include break(tablet) {
        padding-bottom: $pad-large_mobile;
      }

      .wrapper-link {
        @include break(tablet) {
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
        }
      }
    }

    .description {
      max-width: 40%;

      @include break(tablet) {
        max-width: 100%;
      }
    }

    .grid-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      gap: 0px 20px;
      grid-auto-flow: row;
      grid-template-areas:
        ". .";
      margin-left: auto;
      margin-right: 0;

      @include break(small-screen) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }

      @include break(tablet) {
        gap: 0 16px;
        display: inline-flex;
        overflow-x: scroll;
        flex-wrap: nowrap;

        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      a {
        position: relative;
        height: fit-content;

        @include break(tablet) {
          height: fit-content;
          min-width: 100%;
        }

        @include break(mobile) {
          min-width: 90%;
        }

        &:hover {
          &:not(a.link) {
            .arrow-wrapper {
              .arrow-btn {
                &.first {
                  opacity: 0;
                  transform: translateX(100%);
                }

                &.last {
                  opacity: 1;
                  transform: translateX(0) rotate(-45deg);
                }
              }
            }
          }
        }

        .img-container {
          position: relative;
          height: 225px;
          margin-bottom: 1rem;

          @include break(tablet) {
            margin-bottom: 12px;
          }

          .link {
            position: absolute;
            bottom: 0.5rem;
            right: 1rem;
            padding: 0;

            @include break(tablet) {
              bottom: 0.5rem;
            }
          }
        }

        .image {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 225px;
          object-fit: cover;
          clip-path: url(#clip-path-img-mobile);

          @include break(tablet) {
            height: 225px;
            margin-bottom: 12px;
          }
        }

        .slide-content {
          .title-h3 {
            font-size: 21px;
            line-height: 120%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;


            @include break(tablet) {
              font-size: 18px;
            }
          }
        }

        .categories {
          position: absolute;
          top: 12px;
          left: 12px;
        }
      }
    }
  }
}