.module-10-services {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .container {
    @include break(tablet) {
      position: relative;
    }

    .row:first-child {
      padding-bottom: $pad-title;

      @include break(tablet) {
        padding-bottom: $pad-large_mobile;
      }

      .description {
        font-size: 20px;
        
        @include break(tablet) {
          font-size: 18px;
        }
      }
    }
  }

  .col-grid {
    width: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 12px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . .";

    @include break(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fill, 1fr);
      grid-template-areas:
        ".";
    }

    .card {
      width: 100%;
      min-width: 100%;
      height: 530px;
      padding: 40px $pad-small $pad-small $pad-small;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $primary-yellow;
      border-radius: $rounded-lg;
      transition: all .25s ease-out;

      @include break(tablet) {
        height: 475px;
        position: relative;
      }

      @include break(desktop-only) {
        &:hover {
          background-color: $primary-blue;

          .card-header {
            svg {
              transform: rotate(-90deg);
            }
          }

          .card-body {
            display: none;
          }

          .card-body-hover {
            display: block;
          }
        }
      }

      .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include break(tablet) {
          max-width: 90%;
        }

        .title-h3 {
          max-width: 80%;

          @include break(small-screen) {
            font-size: 2.5vw;
          }

          @include break(tablet) {
            font-size: 1.625rem;
          }
        }

        .icon {
          height: 57px;
          width: 57px;
        }

        svg {
          max-width: 57px;
          max-height: 57px;
          transition: transform .25s ease-out;

          @include break(tablet) {
            display: none;
          }
        }
      }

      .card-body {
        display: block;

        @include break(tablet) {
          position: absolute;
          top: 40px;
          right: $pad-small;
        }

        img {
          margin: -12px;

          @include break(tablet) {
            max-width: 68px;
            max-height: 68px;
          }
        }
      }

      .card-body-hover {
        display: none;

        @include break(tablet) {
          display: block;
        }

        .description {
          font-size: 1rem;
          line-height: 150%;
          padding-bottom: 26px;

          @include break(tablet) {
            padding-bottom: $pad-content;
            font-size: 16px;
          }
        }
      }
    }
  }

  .wrapper-title {
    padding-bottom: $pad-small;

    @include break(tablet) {
      padding-bottom: 24px;
    }
  }
}