.module-09-expertises {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .container {
    @include break(tablet) {
      position: relative;
    }
  }

  .col-media {
    position: relative;

    @include break(tablet) {
      padding-bottom: $pad-med_mobile;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      -o-object-fit: cover;
      object-fit: cover;

      &:not(.mobile) {
        @include break(tablet) {
          display: none;
        }
      }

      &.mobile {
        @include break(tablet) {
          display: block;
        }
      }
    }

    .tooltip {
      right: 48px;
    }
  }

  .col-texte {
    .wrapper-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $pad-large;

      @include break(tablet) {
        padding-bottom: $pad-large_mobile;
      }

      .description {
        font-size: 1.25rem;
        line-height: 150%;
        padding-bottom: 100px;

        @include break(tablet) {
          padding-bottom: $pad-content;
          font-size: 1.125rem;
        }
      }
    }

    .list-arrow {
      list-style: none;
      width: 100%;

      li.item {
        position: relative;
        border-bottom: 1px solid $dark;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: $primary-yellow;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          transform: scaleY(0);
          transform-origin: 0 100%;
          transition: transform .25s ease-out;
        }

        &:hover {
          &::before {
            transform: scaleY(1);
          }

          .arrow {
            transform: translateX(10px) rotate(-45deg);
          }
        }

        a {
          display: flex;
          align-items: center;
          padding: 11.5px 0;
          position: relative;
          z-index: 1;

        }

        .arrow {
          display: flex;
          align-items: center;
          transition: transform .25s ease-out;
        }

        .item-text {
          padding-left: $pad-title;
          font-size: 22px;
          
          @include break(tablet) {
            font-size: 18px;
            padding-left: $pad-content;
          }
        }
      }
    }
  }

  .wrapper-title {
    padding-bottom: $pad-small;
    padding-right: 10%;
    
    @include break(tablet) {
      padding-bottom: 24px;
      padding-right: 0;
    }
  }
}