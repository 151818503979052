.module-17-individus {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-xlarge_mobile 0;
  }

  .row {
    .wrapper-title {
      .title-h2 {
        margin-bottom: 100px;

        @include break(tablet) {
          margin-bottom: $pad-small;
        }
      }
    }
  }

  .grid-individus {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px $pad-large_mobile;

    @include break(small-screen) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break(mobile) {
      grid-template-columns: repeat(1, 1fr);
      gap: 70px 0;
    }

    .card-individu {
      width: 100%;
      height: 100%;
      position: relative;
      color: $white;
      transition: none;

      .card-filter {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
        z-index: 1;
      }

      .card-media {
        position: relative;
        width: 100%;
        min-height: 600px;
        margin-bottom: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        overflow: hidden;

        &:hover {
          @include break(desktop-only) {
            .cross span {
              &:last-of-type {
                transform: rotate(-45deg) scale(0);
              }
            }

            .card-media_overlay-bg {
              transform: scale(28);
            }

            .card-media_overlay {
              color: $white;
            }

            .card-project_overlay-bg {
              transform: scale(28);
            }

            .card-media_txt {
              opacity: 1;
              transition: all .1s ease-out 0;
              transform: translateY(0);
            }
          }
        }
      }

      .card-media_bottom {
        width: 59px;
        height: 59px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: grid;
        place-items: center;

        .cross {
          position: relative;
          width: 50%;
          height: 50%;
          z-index: 4;

          span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            background-color: $white;
            transition: all 0.3s ease-out;

            &:first-of-type {
              top: 50%;
            }

            &:last-of-type {
              top: 50%;
              transform: rotate(90deg);
            }
          }
        }
      }

      .card-media_overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 32px 28px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: background-color 0.4s;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;

      }

      .card-media_overlay-bg {
        position: absolute;
        width: 59px;
        height: 59px;
        background-color: $dark;
        border-radius: 12px 0 12px 0;
        bottom: 0;
        right: 0;
        transition: all 0.4s;
        z-index: 1;
      }

      .card-media_txt {
        opacity: 0;
        overflow: auto;
        z-index: 2;
        position: relative;
        transform: translateY(20px);
        transition: all .25s ease-out .15s;

        &::-webkit-scrollbar {
          width: 6px;
          background-color: rgba(255, 255, 255, 0.10);

          &-thumb {
            width: 4px;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0.50);
          }
        }

        @include break(tablet) {
          -webkit-overflow-scrolling: touch;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &-inner {
          font-size: 15px;
          line-height: 150%;
        }
      }

      .txt_date {
        margin-top: 10px;
      }

      .card-text {
        color: $dark;

        .item-name {
          font-size: 22px;

          @include break(tablet) {
            font-size: 18px;
          }
        }

        .item-titre {
          font-size: 1rem;
          padding-bottom: 8px;
        }

        .item-poste {
          font-size: 14px;
          color: $secondary-grey;
        }
      }

      @include break(tablet) {
        min-height: 545px;
        width: 100%;
      }

      &.clickable {
        cursor: pointer;

        .card-media_overlay-bg {
          transform: scale(28);
        }

        .card-media_txt {
          opacity: 1;
          transform: translateY(0);
        }

        .cross span {
          &:last-of-type {
            transform: rotate(-45deg) scale(0);
          }
        }
      }
    }
  }
}