.module-14-redirections {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  &.carte {
    .row {
      flex-wrap: nowrap;

      @include break(tablet) {
        flex-wrap: wrap;
      }
    }
  }

  .content {
    .title-h2 {
      padding-bottom: $pad-small;

      @include break(tablet) {
        font-size: 2rem;
        padding-bottom: 24px;
      }
    }

    .description {
      font-size: 1.25rem;
      line-height: 150%;

      @include break(tablet) {
        font-size: 1.125rem;
        padding-bottom: $pad-large_mobile;
      }
    }

    .col-desc {
      padding-bottom: $pad-title;

      @include break(tablet) {
        padding-bottom: $pad-large_mobile;
      }
    }

    .grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 100%;
      gap: 0 40px;

      @include break(tablet) {
        gap: 0 16px;
      }

      .card {
        display: flex;
        flex-wrap: wrap;
        align-items: space-between;
        flex: 0 0 50%;
        box-sizing: border-box;
        max-width: calc(50% - 20px);
        padding: 36px;
        color: $white;
        background-color: $dark;
        opacity: 0.7;
        clip-path: url(#mask-redirections-card);
        transition: opacity .25s ease-in-out;

        @include break(tablet) {
          min-height: 200px;
          max-width: calc(50% - 8px);
          padding: 28px 22px;
        }

        &:hover {
          opacity: 1;

          .arrow-wrapper {
            .arrow-btn {
              &.first {
                opacity: 0;
                transform: translateX(100%);
              }

              &.last {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }

        .title {
          font-size: 28px;
          line-height: 120%;
          
          @include break(mobile) {
            font-size: 4.5vw;
          }
        }

        .btn {
          padding: 0;
          align-items: flex-end;
        }
      }

      &.grid-list {
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(4, 1fr);

        @include break(small-screen) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include break(tablet) {
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }

        @include break(mobile) {
          grid-template-columns: 1fr;
        }

        .card {
          min-height: 390px;
          max-width: 100%;
          flex-direction: column;
          align-items: flex-start;

          @include break(tablet) {
            min-height: 285px;
            padding: 36px 28px;
          }

          .categories {
            align-items: flex-start;
            flex: 1;
            background-color: transparent;
            border: none;
            color: $primary-yellow;
            padding: 0;

            span {
              text-transform: initial;
              font-size: 1rem;
            }
          }

          .title {
            padding-bottom: $pad-large_mobile;

            @include break(tablet) {
              padding-bottom: 24px;
              font-size: 28px;
            }
          }

          .arrow-wrapper {
            margin-left: 0;
          }
        }
      }
    }
  }

  .card-show {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 40px 75px;
    border-radius: 6px;
    background-color: $primary-yellow;

    @include break(tablet) {
      flex-direction: column;
      justify-content: center;
      padding: 40px 28px;
    }

    .card-text {
      max-width: 65%;

      @include break(tablet) {
        margin-bottom: $pad-large_mobile;
        max-width: 100%;
      }

      .description {
        font-size: 36px;
        line-height: 120%;
        padding-bottom: $pad-med;

        @include break(tablet) {
          font-size: 28px;
        }
      }
    }

    .card-img {
      position: relative;
      max-width: 25%;

      @include break(tablet) {
        max-width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 290px;
        object-fit: cover;
        clip-path: url(#mask-redirections-card);

        @include break(tablet) {
          min-height: 225px;
          margin-bottom: 12px;
          clip-path: url(#mask-redirections-card-mobile);
        }
      }

      .tooltip {
        bottom: calc(100% - 60px);
        right: 0;
      }
    }
  }
}