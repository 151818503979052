@mixin font-face-global($font-name, $file-name, $font-weight, $font-style) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + ".eot");
    src: url("../fonts/" + $file-name + ".eot?#iefix") format('embedded-opentype'),
    url("../fonts/" + $file-name + ".svg#" + $file-name) format('svg'),
    url("../fonts/" + $file-name + ".ttf") format('truetype'),
    url("../fonts/" + $file-name + ".woff") format('woff'),
    url("../fonts/" + $file-name + ".woff2") format('woff2');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@include font-face-global('PP Fragment Light', PPFragment-SansLight, 300, normal);
@include font-face-global('PP Fragment Light Italic', PPFragment-SansLightItalic, 300, italic);
@include font-face-global('PP Fragment Medium', PPFragment-SansMedium, 500, normal);
@include font-face-global('PP Fragment Medium Italic', PPFragment-SansMediumItalic, 500, italic);

//FONT
@mixin font-regular {
  font-family: 'PP Fragment Light', sans-serif;
  font-weight: 300;
}

@mixin font-regular-italic {
  font-family: 'PP Fragment Light Italic', sans-serif;
  font-weight: 300;
}

@mixin font-medium {
  font-family: 'PP Fragment Medium', sans-serif;
  font-weight: 500;
}

@mixin font-medium-italic {
  font-family: 'PP Fragment Medium Italic', sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: 'PP Fragment';
  src: url('PPFragment-SansMedium.eot'); /* IE9+ Compat Modes */
  src: url('PPFragment-SansMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('PPFragment-SansMedium.woff2') format('woff2'), /* Super modern browsers */
  url('PPFragment-SansMedium.woff') format('woff'), /* Modern browsers */
  url('PPFragment-SansMedium.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('PPFragment-SansMedium.svg#FontName') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
}