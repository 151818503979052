.module-05-partenaires-logos {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .col {
    width: 100%;
  }

  .wrapper-title {
    padding-bottom: $pad-large;

    .title-h2 {
      text-align: center;
      font-size: 1.25rem;
      line-height: 120%;
    }
  }

  .bandeau_container {
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-inline-box;
    padding: 0 48px;

    @include break(tablet) {
      padding-bottom: $pad-xlarge_mobile;
    }
  }

  .logos-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    @include break(tablet) {
      display: flex;
      overflow-x: auto;
    }
  }

  .logo-wrapper {
    flex: 0 0 auto;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bandeau {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

  .logo-slide {
    display: inline-flex;
    width: 100%;
    animation: 18s slide infinite linear;
    
    @include break(tablet) {
      animation: 16s slide infinite linear;
    }
  }

  .logo-slide img {
    max-height: 60px;
    width: clamp(170px, 170px, 170px);
    margin: 0 40px;

    @include break(tablet) {
      width: clamp(130px, 130px, 130px);
      margin: 0 $pad-med_mobile;
    }
  }


  .image {
    max-width: 100%;
    height: auto;
    opacity: 0.6;
  }
}