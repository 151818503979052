html {
  overflow-x: hidden;
  overflow-y: scroll;

  &.overflow {
    overflow: hidden;
  }

  background-color: $primary-grey;
  color: $dark;

  &::-webkit-scrollbar {
    // width: 0 !important
  }

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  &.overflow {
    overflow: hidden;
  }

  position: relative;
  background-color: $primary-grey;
  color: $dark;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;

  @include break(mobile) {
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

// .loader {
// position: fixed;
// top: 0;
// left: 0;
// right: 0;
// height: 100vh;
// z-index: 9999;
// z-index: 9999999999;
// background-color: #ffffff;
// text-align: center;
// }

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: $container;
}

.flex {
  display: flex;
  justify-content: space-between;

  >* {
    width: 50%;
  }
}

img {
  &.mobile {
    display: block;

    @include break(mobile) {
      display: none;
    }

    &-display {
      display: none;

      @include break(mobile) {
        display: block;
      }
    }
  }
}

.desktop {
  @include break(mobile) {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;

  // &:hover {
  //   color: $primary;
  // }
}

.description {
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.categories {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background-color: $primary-yellow;
  max-height: 29px;

  span {
    @include font-medium;
    text-transform: capitalize;
    text-align: center;
    font-size: 14px;
    line-height: 0;
    padding: 6px 13px;
    height: 29px;
    display: flex;
    align-items: center;

  }
}

h1,
h2,
h3,
h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

ul {
  list-style-type: square;
}

// p a {
//   color: $primary;
// }

.ghost {
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}


h1,
.title-h1 {
  @include font-regular;
  // font-size: 5.5em;
  // line-height: 120%;
  // margin: 0;
  // @include break(tablet){
  //   font-size: 2.75em;
  // }
}

h2,
.title-h2 {
  @include font-regular;
  font-size: 3rem;
  line-height: 120%;
  margin: 0;

  @include break(tablet) {
    font-size: 2rem;
  }
}

h3,
.title-h3 {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 100%;
  margin: 0;

  @include break(tablet) {
    font-size: 1.625rem;
  }
}

h4,
.title-h4 {
  @include font-regular;
  font-size: 1.55rem;
  line-height: 120%;
  margin: 0;

  @include break(tablet) {
    font-size: 1.375rem;
  }
}

h5,
.title-h5 {
  //@include font-regular;
  // font-size: 1.33em;
  // line-height: 120%; 
  // margin: 0;
  // @include break(tablet){
  //   font-size: 1.25em;
  // }
}

h6,
.title-h6 {
  //@include font-regular;
  // font-size: 1.11em;
  // line-height: 120%; 
  // margin: 0;
  // padding-bottom: $pad-xxsmall;
  // @include break(tablet){
  //   font-size: 1.125em;
  // }
}

.grecaptcha-badge {
  visibility: hidden;
}

.post-password-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1172px;
  padding-top: 150px;
  padding-bottom: 100px;
}

// Background
.bg_white {
  background-color: $white;
}

.bg_dark {
  background-color: $dark;
}

// Boutons / Link
a.btn,
div.btn,
a.link,
div.link {
  display: inline-flex;
  padding: 12px 22px;
  @include font-medium;

  .arrow-wrapper {
    position: relative;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    overflow: hidden;

    .arrow-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: opacity 0.3s, transform 0.3s ease-out;

      @include break(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.first {
        opacity: 1;
        transform: translateX(0);
        z-index: 2;
      }

      &.last {
        opacity: 0;
        transform: translateX(-100%);
        z-index: 1;
      }
    }
  }

  &:hover {
    .arrow-wrapper {
      .arrow-btn {
        &.first {
          opacity: 0;
          transform: translateX(100%);
        }

        &.last {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}

a.btn,
p.btn {
  border-radius: 6px;
  background-color: $dark-light;
  color: $white;

  &:hover {
    background-color: $dark;
  }
}

a.btn.btn-external {
  @include font-medium;
  align-items: center;
  padding-left: 0;
  font-size: 16px;
  line-height: 120%;
  color: $dark;
  background-color: transparent;
}

a.link {
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  transition: all 0.3s;

  svg {
    margin-left: 12px;

    path {
      transition: all 0.3s;
    }
  }
}


// Titre h2 - section
.title_top {
  padding-right: 40%;

  @include break(tablet) {
    padding-right: 0%;
  }

  .txt {
    @include break(tablet) {}
  }
}


// Padding
.sec-padding_grand {
  @include padding-section_big;
}

.sec-padding_petit {
  @include padding-section;
}


// POPUP
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999999;
  align-items: center;
  justify-content: center;

  .inner {
    position: absolute;
    width: 80%;
    height: 80vh;
    transform-origin: center;
    display: flex;
    align-items: center;
    @include absolute-center(center);
    z-index: 2;
    pointer-events: none;
  }

  .video_wrap {
    pointer-events: all;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    transition: all 0.4s;

    span {
      font-style: italic;
    }

    svg path {
      transition: all 0.4s;
    }
  }

  .bg {
    background: rgba($dark, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}


// MEDIAS
.video_wrap {
  pointer-events: all;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper-media {
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  video {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
}

svg.clippy {
  position: absolute;
  top: -999px;
  left: -999px;
  width: 0;
  height: 0;
}