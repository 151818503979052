.module-01-header {
  position: relative;

  &.home {
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    padding: 200px 0 $pad-large 0;

    @include break(tablet) {
      padding: $pad-xlarge 0 $pad-xlarge_mobile 0;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      height: 100%;

      .wrapper-title {
        width: 100%;

        @include break(tablet) {
          margin-top: 30px;
        }
      }

      .title-h1 {
        margin: 0;
        font-size: 4.75rem;
        line-height: 120%;
        color: $white;

        @include break(tablet) {
          font-size: 2.75rem;
          max-width: 100%;
        }

        .words {
          height: 9.6rem;
          line-height: 6rem;
          overflow: hidden;
          display: inline-block;
          position: relative;
          vertical-align: bottom;
          width: 100%;
          box-sizing: content-box;
          transition: all 0.7s;

          @include break(tablet) {
            line-height: 4rem;
          }

          .span-animated {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transform: translateY(-100%);
            transition: transform 0.8s;
            will-change: transform, opacity;
            text-wrap: nowrap;

            @include break(tablet) {
              transition: transform 0.4s;
            }

            &.current {
              opacity: 1;
              animation: slideUp 0.9s ease-out 0.1s;
              transform: translateY(0);
              transition: transform 0.8s, opacity 0.7s;

              @include break(tablet) {
                animation: none;
                transition: transform 0.5s, opacity 0.3s;
              }
            }

            &.next {
              transform: translateY(100%);
              transition: transform 0.8s;

              @include break(tablet) {
                transition: transform 0.5s;
              }
            }

            &.fade-out {
              opacity: 0;
              transform: translateY(-100%);
              transition: transform 0.8s, opacity 0s 0.3s;

              @include break(tablet) {
                transition: transform 0.5s, opacity 0s 0.3s;
              }
            }
          }
        }
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include break(tablet) {
        justify-content: flex-end;
      }

      a.btn-scrollTo {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(237, 230, 228, .2);
        width: clamp(70px, 100%, 70px);
        height: clamp(70px, 100%, 70px);
        display: flex;
        align-items: center;
        justify-content: center;
        @include circle();

        @include break(tablet) {
          display: none;
        }

        &:hover {
          .arrow-wrapper {
            .arrow-btn {
              &.first {
                transform: translate(-50%, 50%);
              }

              &.last {
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .arrow-wrapper {
          width: 70px;
          height: 70px;
          margin-left: 0;

          .arrow-btn {
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.first {
              transform: translate(-50%, -50%);
            }

            &.last {
              transform: translate(-50%, -150%);
            }
          }
        }
      }

      .logo {
        width: 100%;
        max-width: 204px;
      }
    }
  }

  &.page {
    height: 100%;
    width: 100vw;
    min-height: 697px;
    padding: $pad-xlarge 0;
    display: flex;
    align-items: center;

    @include break(tablet) {
      padding: $pad-xlarge 0 $pad-large 0;
    }

    &.flex-block_first {
      margin-bottom: $pad-xlarge_mobile;

      @include break(tablet) {
        margin-bottom: 20px;
      }

      &.noimg {
        margin-bottom: 0;
        min-height: unset;
        padding-top: 230px;
        padding-bottom: 100px;

        @include break(tablet) {
          padding-top: 150px;
        }
      }
    }

    .content {
      .wrapper-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        max-width: 100%;

        .title-h1 {
          margin: 0;
          margin-top: 2rem;
          font-size: 3.75rem;
          line-height: 130%;
          display: flex;
          flex-direction: column;

          @include break(tablet) {
            font-size: 2.5rem;
          }

          .title-ligne1,
          .title-ligne2,
          .title-ligneBis {
            padding: 20px;
            background-color: $primary-grey;
            width: fit-content;
          }

          .title-ligne1 {
            border-radius: $rounded-lg $rounded-lg $rounded-lg 0;
          }

          .title-ligne2 {
            margin-top: -10px;
            padding-top: 0;
            border-radius: 0px $rounded-lg $rounded-lg $rounded-lg;

            @include break(tablet) {
              border-radius: 0px 0px $rounded-lg $rounded-lg;
            }
          }

          .title-ligneBis {
            border-radius: 0px $rounded-lg $rounded-lg 0;

            @include break(tablet) {
              padding-top: 0;
            }
          }

          .title-single-line {
            border-radius: $rounded-lg;
          }

          &:has(.title-ligneBis) {
            .title-ligne1 {
              border-radius: $rounded-lg $rounded-lg 0 0;
            }
          }

        }

        .title-h2 {
          font-size: 1rem;
          padding: 5px 8px;
          background-color: $primary-yellow;
          border-radius: 6px;
        }
      }
    }

    .bg {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      object-fit: cover;
    }

    .filter {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 22.38%);
    }

    &.noimg {
      .content {
        .wrapper-title {
          .title-h1 {

            .title-ligne1,
            .title-ligne2,
            .title-ligneBis {
              padding-left: 0;
            }
          }
        }
      }

      .filter {
        display: none;
      }

      // gestion du media dans le module 02
      &+.module-02-contenu.layout-img-txt .wrapper-media {
        @include break(tablet) {
          display: block;
        }
      }
    }
  }

  &.master {
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    padding: $pad-xlarge 0 300px 0;

    @include break(tablet) {
      padding: 215px 0 $pad-large 0;
    }

    &.flex-block_first {
      padding-top: 160px;

      @include break(tablet) {
        padding-top: 220px;
      }
    }

    .content {
      .wrapper-title {
        padding-left: 25%;
        padding-bottom: 140px;

        @include break(tablet) {
          padding-left: 0;
          padding-bottom: $pad-med_mobile;
        }

        .title-h1 {
          margin: 0;
          font-size: 4.75rem;
          line-height: 120%;

          @include break(tablet) {
            font-size: 2.75rem;
          }

          .span-container {
            padding-top: $pad-title;
            padding-left: 40%;

            @include break(tablet) {
              text-align: right;
              padding-left: 0;
              padding-top: 1rem;
            }
          }
        }
      }

      .wrapper {
        display: flex;
        justify-content: flex-end;

        .description {
          max-width: 60%;
          font-size: 1.25rem;
          line-height: 150%;

          @include break(tablet) {
            font-size: 1.125rem;
            max-width: 100%;
          }
        }
      }
    }

    .bg {
      height: 100%;
      width: 100%;
      max-width: 50%;
      max-height: 90%;
      margin: auto 0;

      @include break(tablet) {
        width: 100%;
        height: 215px;
        max-width: none;
        min-height: fit-content;
        margin: 0 auto;
      }

      picture,
      video,
      iframe {
        left: 30%;
        object-fit: contain;

        @include break(tablet) {
          left: 50%;
        }

        img {
          object-fit: contain;

          @include break(small-screen) {
            object-fit: cover;
          }

          @include break(tablet) {
            object-fit: contain;
          }

          &.mobile {
            @include break(tablet) {
              display: none;
            }

            &-display {
              @include break(tablet) {
                display: block;
              }
            }
          }
        }
      }
    }

    .filter {
      display: none;
    }
  }

  .bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    picture,
    video,
    img {
      object-fit: cover;
      @include absolute-center(center);
      width: 100%;
      height: 100%;
    }

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-aspect-ratio: 16/9) {
        height: 56.25vw;
        width: 100vw;
      }

      @media (max-aspect-ratio: 16/9) {
        width: 177.78vh;
        height: 100vh;
      }
    }

    video {
      &.mobile {
        display: block;

        @include break(tablet) {
          display: none;
        }

        &.mobile-display {
          display: none;

          @include break(tablet) {
            display: block;
          }
        }
      }
    }
  }

  .filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #ACACAC;
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  // gestion du media dans le module 02
  &+.module-02-contenu.layout-img-txt .wrapper-media {
    @include break(tablet) {
      display: none;
    }
  }
}