.module-04-cartes-outline {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .wrapper-title {
    padding-bottom: $pad-title;

    @include break(tablet) {
      padding-bottom: $pad-large_mobile;
    }
  }

  .card-contents {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 39px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . ."
      ". . .";

    @include break(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: $rounded-lg;
      grid-template-areas:
        "."
        "."
        ".";
    }

    &-tall {
      grid-template-rows: 1fr 1fr;
    }

    .card-content {
      padding: $pad-small 26px;
      border-radius: $rounded-lg;
      border: 1px solid $primary-brown;
      position: relative;

      .title-h3 {
        font-size: 1.625rem;
        padding-bottom: $rounded-lg;
        line-height: 120%;

        @include break(tablet) {
          font-size: 22px;
        }
      }

      .description {
        line-height: 150%;
        padding-bottom: $pad-small;
      }

      .index {
        color: $primary-brown;
        font-size: 1.25rem;

        @include break(tablet) {
          font-size: 18px;
        }
      }
    }

    &.variant {
      .card-content {
        &:nth-child(1) {
          &::before {
            content: url("../../dist/img/bloc-2.svg");
            display: block;
            position: absolute;
            bottom: -92px;
            right: -80px;
            z-index: 1;

            @include break(tablet) {
              display: none;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          @include break(desktop-only) {
            border: none;
            background-color: $primary-brown;
            position: relative;

            &>* {
              position: relative;
              z-index: 1;
            }

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% - 2px);
              height: calc(100% - 2px);
              display: block;
              background-color: $primary-grey;
              z-index: 0;
            }
          }
        }

        &:nth-child(2) {
          @include break(desktop-only) {
            clip-path: url(#mask-outline-card-left);

            &::before {
              clip-path: url(#mask-outline-card-left);
            }
          }
        }

        &:nth-child(3) {
          &::before {
            content: url("../../dist/img/bloc.svg");
            display: block;
            position: absolute;
            bottom: -51px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;

            @include break(tablet) {
              display: none;
            }
          }
        }

        &:nth-child(4) {
          @include break(desktop-only) {
            clip-path: url(#mask-outline-card-right);

            &::before {
              clip-path: url(#mask-outline-card-right);
            }
          }
        }
      }
    }
  }
}