.module-15-contenu-grille {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    gap: 60px 40px;

    @include break(tablet) {
      gap: 32px;
    }

    @include break(mobile) {
      grid-template-columns: 1fr;
    }


    .card {
      display: flex;
      flex-wrap: wrap;
      align-items: space-between;
      box-sizing: border-box;
      height: fit-content;

      .card-media {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 240px;
        max-height: 240px;
        border-radius: $rounded-lg;
        background-color: $white;
        margin-bottom: $pad-content;
        overflow: hidden;

        @include break(tablet) {
          min-height: 185px;
          max-height: 185px;
        }

        video {
          width: 100%;
        }
      }

      .card-text {
        .title-h3 {
          font-size: 22px;
          line-height: 120%;
          padding-bottom: 8px;

          @include break(tablet) {
            font-size: 18px;
          }
        }

        .categorie {
          color: $secondary-grey;
          font-size: 14px;
          line-height: 120%;
          padding-bottom: 14px;
        }

        .description {
          font-size: 15px;
          line-height: 150%;
          padding-bottom: 6px;

          @include break(tablet) {
            font-size: 14px;
          }
        }
      }
    }
  }
}