.template-carrieres {
    padding-top: 113px;

    .header_article {
        height: 610px;
        position: relative;
        margin-bottom: 100px;

        @include break(tablet) {
            height: 345px;
            margin-bottom: $pad-large_mobile;
        }

        .img {
            height: 100%;
            width: 100%;
            background-image: url(../../dist/img/placeholder.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        >.container {
            position: relative;

            .categorie {
                position: absolute;
                bottom: calc(40px - 6px);
                left: 0;
                padding: 6px 13px;
                border-radius: 40px;
                background-color: $primary-yellow;
                @include font-medium;
                font-size: 14px;
                line-height: 120%;
            }
        }
    }

    .page-container {
        .title-h1 {
            @include break(tablet) {
                margin-top: 0;
                margin-bottom: 50px;
            }
        }
    }

    .content {
        padding-bottom: $pad-small;

        .divider {
            display: block;
            height: 1px;
            width: 100%;
            margin: 50px 0;
            background-color: $dark;
            opacity: 0.2;
        }

        .lieu {
            .lieu-titre {
                padding-bottom: 10px;
                font-size: 14px;
            }

            .lieu-localisation {
                font-size: 15px;
            }

            .divider {
                margin-top: 80px;

                @include break(tablet) {
                    margin-top: 50px;
                }
            }
        }

        .description {
            .preambule {
                font-size: 26px;
                line-height: 125%;
                margin-bottom: 80px;

                @include break(tablet) {
                    font-size: 22px;
                    margin-bottom: 50px;
                }
            }

            .texte {
                .title-h2 {
                    font-size: 36px;
                    line-height: 105%;
                    margin-bottom: $pad-small;

                    @include break(tablet) {
                        font-size: 26px;
                        margin-bottom: 24px;
                    }
                }

                .texte-content {
                    margin-bottom: 50px;
                }
            }
        }

        .content-footer {
            .title-h2 {
                font-size: 36px;
                line-height: 105%;
                margin-bottom: $pad-small;

                @include break(tablet) {
                    font-size: 26px;
                    margin-bottom: 24px;
                }
            }

            .texte-content {
                padding-bottom: $pad-small;
            }

            .wrapper-CTA {
                display: flex;
                gap: 10px;
                padding-bottom: 50px;

                @include break(tablet) {
                    flex-wrap: wrap;
                    padding-bottom: 40px;
                }
            }

            .note {
                font-size: 14px;
                line-height: 120%;
            }
        }
    }

    .back-to {
        width: 100%;
        padding: 85px 0;
        justify-content: center;
        color: var(--Blanc, #FFF);
        font-size: 28px;
        line-height: 120%;
        border-radius: 0;
        background-color: $primary-brown;

        @include break(tablet) {
            padding: 50px 0;
            font-size: 20px;
            align-items: center;
        }

        .arrow-wrapper {
            margin-left: 0;
            margin-right: 20px;

            @include break(tablet) {
                margin-top: -5px;
            }
        }
    }
}