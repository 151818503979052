.tooltip {
    height: 20px;
    width: 20px;
    margin: 20px;
    display: grid;
    place-items: center;
    position: absolute;
    bottom: 0;
    right: 10px;
    color: $dark;
    font-size: 14px;
    line-height: 120%;
    border: 0;
    border-radius: 50%;
    background: $primary-blue;
    z-index: 10;

    &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translate(-50%, 10px);
        border-width: 4px 6px 0 6px;
        border-radius: 12px;
        border-style: solid;
        border-color: $primary-blue transparent transparent transparent;
        z-index: 100;
    }

    &-content {
        position: absolute;
        bottom: calc(100% + 6px);
        right: -5px;
        background: $primary-blue;
        color: $dark;
        padding: 10px;
        color: #000;
        text-align: right;
        font-size: 14px;
        line-height: 120%;
        border-radius: 4px;
        pointer-events: none;
        width: 290px;
        transform: translateY(10px);
    }

    &::before,
    .tooltip-content {
        visibility: hidden;
        opacity: 0;
        transition: all .25s ease-out;
    }

    &:hover {
        cursor: help;

        &::before,
        .tooltip-content {
            visibility: visible;
            opacity: 1;
        }

        .tooltip-content {
            transform: translateY(0);
        }

        &::before {
            transform: translate(-50%, 0);
        }
    }
}

#scrollToTop {
    position: absolute;
    bottom: 24px;
    right: 1%;
  
    @include break(tablet) {
      bottom: 0;
      right: 0;
    }
  
    .btn-scrollToTop {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 0;
      overflow-y: hidden;
      padding-left: 0;
      padding-right: 0;
  
      @include break(tablet) {
        padding-top: 15px;
        padding-bottom: 6px;
      }
  
      .arrow-wrapper {
        width: 39px;
        height: 39px;
        margin-left: 0;
        .arrow-btn {
          svg {
            @include break(tablet) {
              width: 28px;
            }
          }
  
          &.first {
            transform: translateY(0);
          }
  
          &.last {
            transform: translateY(100%);
          }
        }
      }
    }
  
    &:hover {
      .btn-scrollToTop {
        .arrow-wrapper {
          .arrow-btn {
            &.first {
              opacity: 0;
              transform: translateY(-100%);
            }
  
            &.last {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }