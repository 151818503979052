// Module 01 Header
@keyframes slideUp {

  0% {
    transform: translateY(100%);
  }

  30% {
    transform: translateY(0);
  }

  55% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}

// Module 02
@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

// Bandeau logos
@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}