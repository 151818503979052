.module-13-contact {
  padding: $pad-large 0;
  position: relative;

  @include break(tablet) {
    padding: 65px 0 250px 0;
  }

  .wrapper-title {
    .title-h1 {
      font-size: 3.5vw;
      margin-top: 0;

      @include break(tablet) {
        font-size: 5vw;
        margin-bottom: 30px;
      }

      @include break(mobile) {
        font-size: 40px;
      }

      .words {
        height: 12.6rem;
        line-height: 5rem;
        overflow: hidden;
        display: inline-block;
        position: relative;
        vertical-align: bottom;
        width: 100%;
        box-sizing: content-box;
        transition: all 0.7s;

        @include break(mobile) {
          height: 8.6rem;
          line-height: 3rem;
        }

        .span-animated {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transform: translateY(-100%);
          transition: transform 1s;
          will-change: transform, opacity;

          @include break(tablet) {
            transition: transform 0.4s;
          }

          &.current {
            opacity: 1;
            animation: slideUp 1s ease-out 0.1s;
            transform: translateY(0);
            transition: transform 0.9s, opacity 0.7s;

            @include break(tablet) {
              animation: none;
              transition: transform 0.5s, opacity 0.3s;
            }
          }

          &.next {
            transform: translateY(100%);
            transition: transform 1s;

            @include break(tablet) {
              transition: transform 0.3s;
            }
          }

          &.fade-out {
            opacity: 0;
            transform: translateY(-100%);
            transition: transform 0.6s, opacity 0s 0.3s;

            @include break(tablet) {
              transition: transform 0.3s, opacity 0s 0.3s;
            }
          }
        }
      }
    }
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;


    picture,
    video,
    img {
      object-fit: cover;
      @include absolute-center(center);
      width: 100%;
      height: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    video {
      &.mobile {
        display: block;

        @include break(tablet) {
          display: none;
        }

        &.mobile-display {
          display: none;

          @include break(tablet) {
            display: block;
          }
        }
      }
    }
  }

  .content {
    position: relative;
    z-index: 2;
  }
}