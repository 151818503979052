.template-contact {
    padding: 230px 0 $pad-large 0;

    @include break(tablet) {
        padding: 132px 0 $pad-large_mobile 0;
    }

    .row {
        flex-wrap: nowrap;

        @include break(tablet) {
            flex-wrap: wrap;
        }

        .col-40 {
            @include break(small-screen) {
                padding-right: 20px;
            }

            @include break(tablet) {
                order: 2;
                padding-right: 48px;
            }
        }

        .col-60 {
            @include break(small-screen) {
                padding-left: 20px;
                padding-right: 110px;
            }

            @include break(tablet) {
                padding-left: 48px;
                padding-right: 48px;
                order: 1;
            }
        }
    }

    .wrapper-title {
        margin-bottom: 145px;

        @include break(tablet) {
            margin-bottom: $pad-xlarge_mobile;
        }

        .title-h1 {
            font-size: 60px;
            margin: 0;

            @include break(tablet) {
                font-size: 40px;
                line-height: 105%;
            }
        }
    }

    .contact-infos {
        position: relative;
        padding: 36px 40px;
        margin-bottom: 24px;
        max-width: 408px;
        border: none;
        background-color: $primary-brown;
        clip-path: url(#mask-contact);

        @include break(tablet) {
            padding: 36px 40px 45px 40px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            display: block;
            background-color: $primary-grey;
            clip-path: url(#mask-contact);
            z-index: 0;
        }

        &>* {
            position: relative;
            z-index: 1;
        }
    }

    .contact-infos-adresse {
        &:nth-child(3) {
            a.link-adresse {
                padding-bottom: $pad-large_mobile !important;
            }
        }

        .title-h2 {
            @include font-medium;
            font-size: 1rem;
            padding-bottom: 8px;

            @include break(tablet) {
                font-size: 14px;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            @include break(tablet) {
                padding-bottom: 24px;
            }

            &:hover {
                .arrow-wrapper {
                    .arrow-btn {
                        &.first {
                            opacity: 0;
                            transform: translateX(100%);
                        }

                        &.last {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }

            .city {
                display: inline-flex;
            }

            .arrow-wrapper {
                position: relative;
                display: block;
                width: 25px;
                height: 25px;
                margin-left: 10px;

                .arrow-btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: opacity 0.3s, transform 0.3s ease-out;

                    &.first {
                        opacity: 1;
                        transform: translateX(0);
                        z-index: 2;
                    }

                    &.last {
                        opacity: 0;
                        transform: translateX(-100%);
                        z-index: 1;
                    }
                }
            }
        }
    }

    .social-list {
        display: flex;
        gap: 12px;

        li {
            display: flex;
            position: relative;

            &:hover {
                cursor: pointer;

                a {
                    transform: scale(0.9);
                }
            }

            a {
                display: flex;
                padding: 14px;
                width: 55px;
                height: 55px;
                border-radius: 50%;
                background-color: $dark;
                transition: transform 0.25s ease-out;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                path {
                    fill: $white;
                }
            }

            img {}
        }
    }

    .contact-RGPD {
        max-width: 80%;

        @include break(tablet) {
            max-width: 100%;
        }

        &-description {
            font-size: 14px;
            line-height: 120%;
            padding-bottom: 10px;
        }

        &-name,
        &-mail {
            font-size: 15px;
            line-height: 150%;
        }

        &-name {
            @include font-medium;
        }
    }

    .form {
        @include break(tablet) {
            margin-bottom: $pad-xlarge_mobile;
        }
    }
}