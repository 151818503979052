.content-editor {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font-regular;
        margin-top: 52px;
        margin-bottom: $pad-small;

        @include break(tablet) {
            margin-bottom: 24px;
        }
    }

    h1 {
        font-size: 60px;
        line-height: 105%;
    }

    h2 {
        font-size: 48px;
        line-height: 105%;
    }

    h3 {
        font-size: 36px;
        line-height: 105%;
    }

    h4 {
        font-size: 28px;
        line-height: 120%;
    }

    h5 {
        font-size: 22px;
        line-height: 120%;
    }

    h6 {
        font-size: 20px;
        line-height: 120%;
    }

    ul,
    ol {
        margin-bottom: 50px;

        @include break(tablet) {
            margin-bottom: 40px;
        }
    }

    ul {
        list-style: none;

        li {
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                background-color: $dark;
                position: absolute;
                top: calc(1rem - 4px);
                left: -16px;
            }
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        a {
            text-decoration: underline;
            transition: color .2s ease-out;

            &:hover {
                color: $dark-light;
            }
        }
    }

    iframe {
        width: 100%;
        border-radius: $rounded-lg;
    }

    legend {
        font-size: 14px;
        line-height: 120%;
        margin: 0;
        margin-top: 10px;
        opacity: 0.4;
    }

    .citation {
        position: relative;
        border: none;
        padding: $pad-large_mobile;
        background-color: $primary-brown;
        clip-path: url(#mask-citation);
        margin-bottom: 50px;

        @include break(tablet) {
            margin-bottom: 40px;
            padding: 28px;
            clip-path: url(#mask-citation-mobile);
        }

        &>* {
            position: relative;
            z-index: 1;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            display: block;
            background-color: $primary-grey;
            z-index: 0;
            clip-path: url(#mask-citation);

            @include break(tablet) {
                clip-path: url(#mask-citation-mobile);
            }
        }
    }

    .author {
        font-size: 15px;
        line-height: 150%;
    }

    .enlighter-default {
        padding: 24px 0;
    }
}

/* !-- WP WYSIWYG Editor Styles -- */
.entry-content img {
    margin: 0 0 1.5em 0;
}

.alignleft,
img.alignleft {
    margin-right: 1.5em;
    display: inline;
    float: left;
}

.alignright,
img.alignright {
    margin-left: 1.5em;
    display: inline;
    float: right;
}

.aligncenter,
img.aligncenter {
    margin-right: auto;
    margin-left: auto;
    display: block;
    clear: both;
}

.wp-caption {
    margin-bottom: 50px;
    padding-top: 5px;
    width: 100% !important;

    @include break(tablet) {
        margin-bottom: 40px;
    }

    .bg_white {
        padding: 15px 20px;
        border-radius: $rounded-lg;
    }

    img {
        border: 0 none;
        padding: 0;
        margin: 0;
        border-radius: 6px;
    }

    p.wp-caption-text,
    figcaption {
        font-size: 14px;
        line-height: 120%;
        margin: 0;
        margin-top: 12px;
        opacity: 0.4;

    }

    .wp-smiley {
        margin: 0 !important;
        max-height: 1em;
    }
}

blockquote,
.citation-text {
    font-size: 36px;
    line-height: 125%;
    margin-bottom: 50px;

    @include break(tablet) {
        font-size: 28px;
        margin-bottom: 40px;
    }

    &.left {
        margin-right: 20px;
        text-align: right;
        margin-left: 0;
        width: 33%;
        float: left;
    }

    &.right {
        margin-left: 20px;
        text-align: left;
        margin-right: 0;
        width: 33%;
        float: right;
    }
}

b,
strong {
    @include font-medium();
}