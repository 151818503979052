footer {
    background-color: $dark;
    color: $white;
    padding-top: $pad-large;
    padding-bottom: $pad-small;
    clip-path: url(#mask-footer);

    @include break(tablet) {
        padding-top: $pad-title;
        padding-bottom: $pad-xsmall;
        clip-path: url(#mask-footer-mobile);
    }

    .grid-footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include break(tablet) {
            grid-template-columns: 1fr;
        }

        .footer-logo {
            @include break(tablet) {
                padding-bottom: 50px;

                a {
                    max-width: 214px;
                    display: block;

                    svg {
                        width: 100%;
                    }
                }
            }
        }

        .footer-form {
            display: flex;
            flex-direction: column;

            .title-h3 {
                font-size: 32px;
                padding-bottom: $pad-large_mobile;
                padding-right: $pad-small;

                @include break(tablet) {
                    font-size: 22px;
                    padding-bottom: $pad-med_mobile;
                }
            }

            .title-h4 {
                @include font-medium;
                font-size: 18px;
                line-height: 150%;
                padding-bottom: 1rem;

                @include break(tablet) {
                    font-size: 16px;
                }
            }

            .mailchimp-form {
                max-width: 90%;
                padding-bottom: $pad-large_mobile;

                @include break(tablet) {
                    max-width: 100%;
                    padding-bottom: $pad-med_mobile;
                }
            }

            .footer-socials {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex: 1;

                @include break(tablet) {
                    margin-bottom: 50px;
                }

                >p {
                    @include font-medium;
                    margin-bottom: 16px;
                }

                .social-list {
                    display: flex;
                    gap: 12px;

                    li {
                        display: flex;
                        position: relative;

                        &:hover {
                            cursor: pointer;

                            a {
                                transform: scale(0.9);
                            }
                        }

                        a {
                            display: flex;
                            padding: 14px;
                            width: 55px;
                            height: 55px;
                            border-radius: 50%;
                            background-color: $white;
                            transition: transform 0.25s ease-out;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

        }

        .menu-footer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .col-left,
            .col-right {
                ul {

                    display: flex;
                    flex-direction: column;
                    gap: 13px;
                }
            }

            .btn-menu-cta {
                @include font-regular;
                height: fit-content;
                width: fit-content;
                margin-top: 100px;
                background-color: $white;
                color: $dark;
                text-wrap: nowrap;

                @include break(tablet) {
                    margin-top: 50px;
                    padding: 14px 16px;
                }

                .arrow-wrapper {
                    width: 14px;
                    height: 20px;
                }
            }
        }
    }

    .footer-copyrights {
        display: flex;
        margin-top: 180px;
        font-size: 14px;
        line-height: 160%;
        color: #999;

        @include break(tablet) {
            margin-top: 50px;
            flex-wrap: wrap;
        }

        .copyrights,
        .legals {
            @include break(tablet) {
                text-wrap: nowrap;
            }
        }
    }
}