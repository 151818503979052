.module-12-testimonials {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }

  .wrapper-title {
    padding-bottom: $pad-small;

    @include break(tablet) {
      padding-bottom: 24px;
    }
  }

  .col-double-cards {
    width: 100%;
    padding-top: 100px;

    @include break(tablet) {
      padding-top: $pad-med;
    }
  }

  .card-video {
    display: flex;
    flex-direction: column;
    max-width: fit-content;

    @include break(small-screen) {
      margin-right: 0;
      margin-left: auto;
      width: 100%;
    }

    @include break(tablet) {
      max-width: none;
      width: 100%;
    }

    .cover {
      width: 450px;
      height: 564px;
      border-radius: $rounded-lg;
      position: relative;

      @include break(tablet) {
        width: 342px;
        height: 432px;
        display: flex;
        justify-content: center;
        min-width: -webkit-fill-available;
      }

      .play-btn {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          .play-btn-object {
            transform: scale(1.1);
          }
        }
      }

      .play-btn-object {
        width: 110px;
        height: 110px;
        display: grid;
        place-items: center;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: $primary-yellow;
        transition: all 0.4s;
        cursor: pointer;

        @include break(tablet) {
          width: 83px;
          height: 83px;
        }
      }

      svg {
        position: absolute;
        width: 38px;
      }
    }
  }


  .wrapper-cards {
    display: grid;
    grid-template-columns: 450px 570px;
    grid-template-rows: 1fr;
    grid-template-areas:
      ". .";
    justify-content: space-between;

    @include break(small-screen) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break(tablet) {
      grid-template-columns: 1fr;
      grid-template-areas:
        ".";
      gap: 20px;
    }

    .card {
      height: fit-content;
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      background-color: $white;
      border-radius: $rounded-lg;
      padding: $pad-med;

      @include break(tablet) {
        padding: 44px 28px;
      }

      &:first-child {
        margin-top: -100%;
        max-width: 450px;

        @include break(small-screen) {
          max-width: 400px;
        }

        @include break(tablet) {
          max-width: none;
          margin-top: 0
        }
      }

      .content {
        padding-bottom: $pad-med;

        .logo {
          max-width: 60px;
        }
      }

      .description {
        font-size: 1.625rem;
        line-height: 124%;

        @include break(tablet) {
          font-size: 1.375rem;
        }
      }

      &.card-mask {
        clip-path: url(#clip-path-card-mask);

        @include break(tablet) {
          clip-path: url(#clip-path-card-mask-mobile);
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $rounded-lg;

    .fonction {
      opacity: 0.5;
    }

    .logo {
      max-width: 90px;
    }
  }

  &.classic {
    >.container {
      padding: 80px 60px;
      background-color: $white;
      clip-path: url(#clip-path-card-mask-full);

      @include break(tablet) {
        padding: $pad-large_mobile 28px;
        clip-path: url(#clip-path-card-mask-full-mobile);
      }
    }

    .col-desc {
      margin-top: -60px;

      @include break(tablet) {
        margin-top: 0;
      }
    }

    .wrapper-title {
      max-width: 65%;

      .title-h2 {
        font-size: 1.25rem;

        @include break(tablet) {
          font-size: 1.125rem;
        }
      }
    }

    .slider-cards {
      &.dots {
        display: flex;
        gap: 10px;

        @include break(tablet) {
          padding-bottom: $pad-large_mobile;
        }

        .card {
          opacity: 0.4;
          transition: opacity .25s ease-out;

          &:hover {
            cursor: pointer;
          }

          &.active {
            opacity: 1;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .picture {
            padding-bottom: 10px;
            max-width: 150px;
            border-radius: 4px;

            @include break(tablet) {
              margin-bottom: 8px;
              max-width: 150px;
              max-height: 150px;
              overflow: hidden;
            }

            img {
              border-radius: 4px;

              @include break(tablet) {
                object-fit: cover;
              }
            }
          }

          .name {
            font-size: 16px;
          }

          .fonction {
            font-size: 12px;
          }
        }
      }

      &.desc {
        .description {
          font-size: 2.25rem;
          padding-bottom: $pad-title;
          line-height: 120%;
          display: none;

          @include break(tablet) {
            font-size: 1.375rem;
            padding-bottom: $pad-large_mobile;
          }

          &.active {
            display: block;
          }
        }
      }
    }

    .btn-testimonials {
      @include break(mobile) {
        max-width: 90%;
        padding-right: 50px;
        position: relative;

        .arrow-wrapper {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }
    }
  }
}