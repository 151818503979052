@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/editorial";
@import "./common/anim-class";

@import "./components/popup";
@import "./components/card";
@import "./components/button";
@import "./components/form";
@import "./components/pagination";

@import "./modules/module-01-header";
@import "./modules/module-02-contenu";
@import "./modules/module-03-panneau-de-cartes";
@import "./modules/module-04-cartes-outline";
@import "./modules/module-05-partenaires-logos";
@import "./modules/module-06-introduction";
@import "./modules/module-08-actualites-realisations";
@import "./modules/module-09-expertises";
@import "./modules/module-10-services";
@import "./modules/module-11-industries";
@import "./modules/module-12-testimonials";
@import "./modules/module-13-contact";
@import "./modules/module-14-redirections";
@import "./modules/module-15-contenu-grille";
@import "./modules/module-16-contenu-img-full";
@import "./modules/module-17-individus";
@import "./modules/news/module-news-01-contenu";
@import "./modules/news/module-news-03-media";
@import "./modules/news/module-news-04-iframe";
@import "./modules/services/module-services-01-graphique";
@import "./modules/services/module-services-02-subventions";

@import './pages/home';
@import './pages/contact';
@import './pages/cpt/single-news';
@import './pages/cpt/single-carrieres';
@import './pages/template-news-archive';
@import './pages/template-industries';
@import './pages/quatrecentquatre';
