.module-06-introduction {
  padding: $pad-large 0;

  @include break(tablet) {
    padding: $pad-large_mobile 0;
  }


  .wrapper-title {
    padding-bottom: $pad-large;

    @include break(tablet) {
      padding-bottom: 24px;
      max-width: 60%;
    }
  }

  .description {
    font-size: 1.25rem;
    line-height: 150%;

    @include break(tablet) {
      font-size: 1.125rem;
    }
  }

  &.CTA {
    .col-30 {
      min-width: 30%;

      @include break(tablet) {
        min-width: auto;
      }
    }

    .description {
      font-size: 30px;
      line-height: 125%;

      @include break(tablet) {
        font-size: 26px;
        margin-bottom: $pad-med;
      }
    }

    .btn-scrollTo {
      color: $dark;
      background-color: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      font-size: 1.625rem;
      margin-top: 70px;
      @include font-regular;

      @include break(tablet) {
        font-size: 1.375rem;
      }

      &:hover {
        .arrow-wrapper {
          .arrow-btn {
            &.first {
              transform: translate(-50%, 50%);
            }

            &.last {
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .arrow-wrapper {
        width: 64px;
        height: 64px;
        margin-left: 0;
        margin-right: $pad-content;
        border-radius: 50%;
        background-color: $primary-yellow;
        display: grid;
        place-items: center;

        .overflow {
          position: relative;
          width: 24px;
          height: 24px;
          overflow: hidden;
        }

        @include break(tablet) {
          width: 43px;
          height: 43px;
        }

        .arrow-btn {
          top: 50%;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.first {
            transform: translate(-50%, -50%);
          }

          &.last {
            transform: translate(-50%, -150%);
          }
        }
      }
    }
  }
}